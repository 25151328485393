import React from "react";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";

const CancelNote = ({
	cancelNote,
	setCancelNote,
  closeCancelModal,
  updateCancelFun,
  cancelSubmit,
  itemCancel,
    setItemCancel
}) => {
  return (
    <div className="pro-p-5">
      <h6 className="pro-ttl h6 pro-mb-1">
        Are You Sure want to cancel the Order?{" "}
      </h6>

      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeCancelModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className="pro-btn lg pro-btn-outline pro-ms-2"
          onClick={() => setItemCancel(true)
          }
        >
          Yes
        </Button>
      </div>
      {itemCancel ? (
        <div className="pro-pt-5">
          <div className="input-wrap pro-mb-4">
            <input
              type="text"
              placeholder="Reason for cancel"
              onChange={(e) => setCancelNote(e.target.value)}
              className="pro-input lg"
            />
          </div>
          <div className="pro-d-flex pro-justify-end">
            <Button
              className="pro-btn lg pro-btn-outline"
              onClick={() => setItemCancel(false)}
            >
              Cancel
            </Button>

            <Button
              className={`pro-btn-primary lg pro-ms-3 ${
                cancelSubmit ? "loading" : ""
              }`}
              type="submit"
              disabled={cancelSubmit}
              onClick={() =>
                updateCancelFun(cancelNote) && setItemCancel(false)
              }
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CancelNote;
