import React, { useState } from 'react';
import { Button } from "@wac-ui-dashboard/wac_component_library";
import useConfirmation from "./useConfirmation";
import Select from "react-select";
import Style from "./editPolicy.module.scss";

const Confirmation = ({
  closeModal,
  handleFunction,
  refetch,
  selectedData,
  count,
}) => {
  const {
    formik,
    statusOptions,
    handleOptionChange,
    selectedOption,
    options,
    bulkDownLoadStatus,
  } = useConfirmation({
    closeModal,
    handleFunction,
    refetch,
    selectedData,
    count,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFormSubmit = async () => {
    // Your form submission logic goes here
    setIsSubmitted(true);
    formik.handleSubmit();
  };

  return (
    <div className="pro-p-5">
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Download Type
          </label>
          <Select
            name="type"
            options={statusOptions} // replace with your franchise options
            onChange={(selectedOption) => {
              formik.setFieldValue("type", selectedOption);
            }}
            className={`pro-input lg ${
              formik.errors.type && formik.touched.type && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            isDisabled={isSubmitted} // Disable the Select when form is submitted
          />
          {formik.touched.type && formik.errors.type && (
            <div className="error">{formik.errors.type}</div>
          )}
        </div>
      </div>
      {formik?.values?.type?.value === 1 && (
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-3 pro-fw-medium">
            Zip Type
          </label>
          <div className="pro-d-flex pro-flex-column pro-gap-2">
            {options.map((option, index) => (
              <div className="pro-check-box radio" key={index}>
                <input
                  type="radio"
                  value={option}
                  id={`radio${index + 1}`}
                  className="pro-check"
                  checked={selectedOption === option}
                  onChange={handleOptionChange}
                  disabled={isSubmitted} // Disable the radio buttons when form is submitted
                />
                <label
                  className="pro-check-label"
                  htmlFor={`radio${index + 1}`}
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
          disabled={isSubmitted} // Disable the Cancel button when form is submitted
        >
          Cancel
        </button>
        <Button
          className={`pro-btn-primary lg pro-ms-3 ${bulkDownLoadStatus === "pending" ? "loading" : ""}`}
          type="submit"
          onClick={handleFormSubmit} // Use your custom form submit handler
          disabled={isSubmitted} // Disable the Submit button when form is submitted
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;
