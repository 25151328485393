import { useDispatch } from "react-redux";
import { deleteFromList } from "../../../store/slices/Orders/ordersSlice";

const useZipFileList = ({ closeModal, refetch }) => {

  const dispatch = useDispatch();

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
 

  const downloadFile = (file) => {
        // Create a link element
    const link = document.createElement('a');
    link.href = file?.file_path_url;
    // Set the download attribute and filename
    link.download = `${file?.file_name}`
    // Append the link to the body
    document.body.appendChild(link);
    // Click the link
    link.click();
    // Clean up
    document.body.removeChild(link);
    setTimeout(() => {
      dispatch(deleteFromList(file?.id))
      .then((res) => {
        if(res?.payload?.status_code === 200) {
          refetch();
        }
      })
    }, 1000); 
  };
  
  return {
    getRow,
    downloadFile
  };
};

export default useZipFileList;
