import { useFormik, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createNonSeasonBasedOffer,
  getRules,
  updateConfig,
  updateNonSeasonBasedOffer,
} from "../../../../store/slices/NonSeasonOffers/nonSeasonOffersSlice.js";
import {
  useGetFrameDataQuery,
  useGetRuleDataQuery,
  useGetSizeDataQuery,
} from "../../../../store/queries/nonSeasonalOffers/index.js";

const useSeasonBasedOfferForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);
  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.nonSeasonOffers
  );
  const { data: SizeData = {}, isSuccess: isOptionDatas } = useGetSizeDataQuery(
    {}
  );
  const { data: FrameData = {}, isSuccess: isFrameDatas } =
    useGetFrameDataQuery({});
  const [ruleList, setRuleList] = useState([]);

  const [is_offer_Type, setOfferType] = useState(
    selectedItemsDetails !== ""
      ? (selectedItemsDetails?.offer_type === 0 ? "0" : "1" )
      : ""
  );
  const [is_rule, setIsRule] = useState(
    selectedItemsDetails !== ""
      ? selectedItemsDetails?.rule_id.toString()
      : ""
  );
  const [is_applicable, setApplicable] = useState(
    selectedItemsDetails !== ""
      ? selectedItemsDetails?.applicable_to.toString()
      : ""
  );

  useEffect(() => {
    dispatch(getRules())
      .unwrap()
      .then((result) => {
        setRuleList(
          result.data.data?.map((item) => ({
            name: `${item.name} (${item.description})`,
            id: item.id,
          }))
        );
      })
      .catch((err) => {});
  }, []);

  const options = [
    {
      name: "Amount",
      id: "0",
    },
    {
      name: "Percentage",
      id: "1",
    },
  ];

  const applicableOptions = [
    {
      name: "All Products",
      id: "0",
    },
    {
      name: "Design",
      id: "4",
    },
  ];

  const [Options, setOptions] = useState(options);
  const [ApplicableOptions, setApplicableOptions] = useState(applicableOptions);

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setOfferTypeDrop(selectedItemsDetails?.rule_id);
      setOfferType(selectedItemsDetails?.offer_type === 1 ? "1" : (selectedItemsDetails?.offer_type === 0 ? "0" : selectedItemsDetails?.offer_type.toString()));
    }
  }, [selectedItemsDetails]);

  const setOfferTypeDrop = (id) => {
    if (id === 4) {
      const newOptions = [...options]; // Create a copy of the existing options array
      newOptions.push({
        name: "Combo",
        id: "2",
      });
      setOptions(newOptions);
      formik.setFieldValue("offer_type", "2");
    } else {
      setOptions(options);
      formik.setFieldValue("offer_type", "");
    }
    if (id !== 1) {
      
      const newOptions = [...applicableOptions]; // Create a copy of the existing options array
      newOptions.push(
        {
          name: "Frame Type",
          id: "1",
        },
        {
          name: "Size Type",
          id: "2",
        }
      );
      setApplicableOptions(newOptions);
      switch (id) {
        case 2:
          formik.setFieldValue("applicable_to", "1");
          break;
        case 3:
          formik.setFieldValue("applicable_to", "2");
          break;
        case 4:
          formik.setFieldValue("applicable_to", "2");
          break;
      }
    } else {
      setApplicableOptions(applicableOptions);
      formik.setFieldValue("applicable_to", "");
    }
  };

  const validation = Yup.object({
    rule_id: Yup.string().required("*Required"),
    code: Yup.string()
      .min(2, "The code must be at least 2 characters")
      .required("*Required"),
    title: Yup.string()
      .min(2, "The title must be at least 2 characters")
      .required("*Required"),
    description: Yup.string()
      .min(2, "The description must be at least 2 characters")
      .required("*Required"),
    offer_type: Yup.string().required("*Required"),
    applicable_to: Yup.string().required("*Required"),
    percentage:
      is_offer_Type === "1"
        ? Yup.number()
        .min(0, "Percentage must be greater than or equal to 0")
        .max(100, "Percentage must be less than or equal to 100")
            .typeError("Percentage must be a number")
            .required("*Required")
        : "",
    amount:
      is_offer_Type === "0"
        ? Yup.number()
            .min(1, "Amount must be greater than 0")
            .typeError("Amount must be a number")
            .required("*Required")
        : "",
    buy_count:
      is_rule === 4
        ? Yup.number()
            .typeError("Buy Count must be a number")
            .required("*Required")
        : "",
    off_count:
      is_rule === 4
        ? Yup.number()
            .typeError("Off Count must be a number")
            .required("*Required")
        : "",
    size_type:
      is_rule === 3 || is_rule === 4
        ? Yup.number().required("*Required")
        : "",
    frame_type: is_rule === 2 ? Yup.number().required("*Required") : "",
  });

  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedItemsDetails !== "" ? selectedItemsDetails.id : "",
      rule_id: selectedItemsDetails !== "" ? selectedItemsDetails?.rule_id : "",
      code: selectedItemsDetails !== "" ? selectedItemsDetails.code : "",
      title: selectedItemsDetails !== "" ? selectedItemsDetails.title : "",
      description:
        selectedItemsDetails !== "" ? selectedItemsDetails.description : "",
      offer_type:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.offer_type.toString()
          : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 0
          : 0,
      percentage:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.offer_type.toString() == "1"
            ? selectedItemsDetails?.percentage
            : ""
          : "",
      amount:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.offer_type.toString() == "0"
            ? selectedItemsDetails?.amount
            : ""
          : "",
      buy_count:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.offer_type.toString() == "2"
            ? selectedItemsDetails?.buy_count
            : ""
          : "",
      off_count:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.offer_type.toString() == "2"
            ? selectedItemsDetails?.off_count
            : ""
          : "",
      frame_type:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.rule_id === 2
            ? selectedItemsDetails?.applicable_id
            : null
          : null,
      applicable_to:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.applicable_to.toString()
          : "",
      size_type:
        selectedItemsDetails !== "" &&
        (selectedItemsDetails?.rule_id === 3 ||
          selectedItemsDetails?.rule_id === 4)
          ? selectedItemsDetails?.applicable_id
          : null,
    },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        id: is_edit ? selectedId : "",
        rule_id: values.rule_id,
        code: values.code,
        title: values.title ? values.title : "",
        description: values.description ? values.description : "",
        offer_type: values.offer_type,
        status: +values.status,
        is_seasonal: 0,
        applicable_to: values?.applicable_to,
      };

      switch (values.rule_id) {
        case 4:
          obj.applicable_id = values?.size_type;
          obj.buy_count = values?.buy_count;
          obj.off_count = values?.off_count;
          break;

        case 2:
          obj.applicable_id = values?.frame_type;
          break;

        case 3:
            obj.applicable_id = values?.size_type;
            break;
        
      }

      switch (values.offer_type) {
        case "1":
          obj.percentage = values?.percentage;
          break;

        default:
          obj.amount = values?.amount;
          break;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if(is_edit) {
        dispatch(updateNonSeasonBasedOffer({ data: formData })).then(
          (response) => {
            if (response?.payload?.status_code === 200) {
              closeModal?.();
              refetch();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              // setApplicableOptions(applicableOptions);
              resetForm();
              toast.success(response?.payload?.message);
            } else if (response?.payload?.status_code === 400) {
              formik.setErrors(response?.payload?.message);
            } else toast.error(response?.payload?.message);
          }
        )
      }
        else {
          dispatch(createNonSeasonBasedOffer({ data: formData })).then(
            (response) => {
              if (response?.payload?.status_code === 200) {
                closeModal?.();
                refetch();
                dispatch(
                  updateConfig((state) => {
                    state.showCreateModal = false;
                  })
                );
                dispatch(
                  updateConfig((state) => {
                    state.clearSelection = true;
                  })
                );
                // setApplicableOptions(applicableOptions);
                resetForm();
                toast.success(response?.payload?.message);
              } else if (response?.payload?.status_code === 400) {
                formik.setErrors(response?.payload?.message);
              } else toast.error(response?.payload?.message);
            }
          );
        }
      
    },
  });

  const setApplicableTo = (id) => {
    setApplicable(id);
    if (id === "0" && formik.values?.size_type) {
      formik.setFieldValue("size_type", null);
      formik.setFieldValue("applicable_id", null);
    }
  };


  return {
    selectedItemsDetails,
    profilefileInputRef,
    formik,
    Options,
    SizeData,
    ApplicableOptions,
    FrameData,
    setApplicableTo,
    setOfferType,
    setApplicable,
    ruleList,
    setOfferTypeDrop,
  };
};

export default useSeasonBasedOfferForm;
