import USER from "./images/header/avatar.png";
import HEADERLOGO from "./images/header/logo.svg";
import IMAGE_APPEARANCE from "./images/image-appearance.png";
import THEME_BLACK from "./images/black.png";
import THEME_LIGHT from "./images/light.png";
import THEME_CLOSED from "./images/closed.png";
import THEME_COMPACT from "./images/compact.png";
import THEME_DARK from "./images/dark.png";
import THEME_DETAILED from "./images/detailed.png";
import THEME_EXPANDED from "./images/expanded.png";
import NO_DATA from './images/no_data.svg';
import EMPTY_DATA from './images/empty_data.png'
import HEADERLOGODARK from "./images/header/logo.svg";
import ACCOUNT_CIRCLE from './images/acount_circle.png'
import FEMALEPROFILE from "./images/female.png";
import MALEPROFILE from "./images/male.png";
import GENDER_IMAGE from "./images/gender_image.png"
import RED_CHECK from "./images/red-check.svg";
import WATER_MARK from "./images/waterMark.png";

const Assets = {
  USER,
  HEADERLOGO,
  IMAGE_APPEARANCE,
  THEME_BLACK,
  THEME_LIGHT,
  THEME_CLOSED,
  THEME_COMPACT,
  THEME_DARK,
  THEME_DETAILED,
  THEME_EXPANDED,
  NO_DATA,
  EMPTY_DATA,
  HEADERLOGODARK,
  FEMALEPROFILE,
  MALEPROFILE,
  ACCOUNT_CIRCLE,
  GENDER_IMAGE,
  RED_CHECK,
  WATER_MARK
};

export default Assets;
