import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
    async ({ params, endpoint, method, body }) => {
      const api = await getAxiosInstance();
      const getParams = (key) => {
        if (params?.[key]) {
          return `${key}=${params[key]}&`;
        } else {
          return "";
        }
      };

      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}${getParams("filter")}${getParams("sort_by")}sort_order=${params?.sort_order || "desc"
          }&length=${params?.length || "10"}&${getParams("search")}page=${params?.page || 1
          }`,
          body
        );

        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;

        return {
          data: err?.response?.data || err.message,
        };
      }
    };

export const enquiries = createApi({
  reducerPath: "enquiriesApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["enquiry", "enquiryStatus"],
  endpoints: (builder) => ({

    getEnquiryListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: '/v1/admin/contact/contact-list'
      }),
      providesTags: ["enquiry"]
    }),

    updateTableFeildsData: builder.mutation({
      query(body) {
        return {
          endpoint: `/v1/admin/table-permission-create`,
          method: 'post',
          body,
        }
      },
      invalidatesTags: ["enquiry"],
    }),

    updateEnquiryStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin/contact/change-status`,
      }),

      invalidatesTags: ["enquiry"],
    }),
  }),
});

export const {


  useGetEnquiryListDataQuery,
  useUpdateTableFeildsDataMutation,
  useUpdateEnquiryStatusMutation
} = enquiries;
