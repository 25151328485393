import React from "react";
import Style from "./orderShippingDetails.module.scss";
import {
  MultiColumnTable,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useOrderShippingDetails from "./useOrderShippingDetails";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";
import AddressForm from "./OrderDetailsForm";
import OrderDetailsForm from "./OrderDetailsForm";

const OrderShippingDetails = () => {
  const {
    orderDetails,
    closeModal,
    refetch,
    handleEditClick,
    orderDetailsEditModal,
    isFetching,
    profileData
  } = useOrderShippingDetails();

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className={`col-12 ${Style.overview_wrap_list}`}>
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Order Details"}
                    data={orderDetails}
                    editIcon={
                        <span className="material-symbols-outlined">
                          {(profileData?.result?.permissions["can_edit"] && profileData?.result?.created_by_type === 1 && (profileData?.result?.status === "Placed" || profileData?.result?.status === "QcCheck" || profileData?.result?.status === "QcCheck Failed"))  ? `edit_square` : ``}
                        </span>
                    }
                    handleEdit={() => handleEditClick()}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

<OffCanvasLayout
          show={orderDetailsEditModal}
          handleClose={closeModal}
          title={"Update Order Details"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <OrderDetailsForm
            refetch={refetch}
            closeModal={closeModal}
          />
        </OffCanvasLayout>

    </div>
  );
};

export default OrderShippingDetails;
