import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useTermsAndCondition from "./useTermsAndCondition";
import EmptyData from "../../Global/EmptyData";
import { ImageCard } from "../../Global/ImageCard";
import EditPolicy from "./EditPolicy";
import Style from './termsAndCondition.module.scss';
// import BannerForm from "./BannerForm";

const TermsAndCondition = () => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleClearClick,
    showCreateModal,
    closeModal,
    handleCreateClick,
    handleEditAction,
    showImageModal,
    closeImageModal,
    imageData,
  } = useTermsAndCondition();

  const parse = require("html-react-parser");
  const content = mainData?.data?.result?.description ?? "";

  return (
    <>
      {/* <h3>Terms And Condition</h3>
      < onClick={handleEditClick}>{parse(content)}<span className="material-symbols-outlined">
              edit_square
            </span></> */}
      <HeadingGroup
        title={"Terms And Condition"}
        extraClassName={`pro-mb-5`}
      >
        <button className="pro-btn pro-btn-primary pro-items-center" onClick={handleEditClick}>
          <span class="material-symbols-outlined">edit_square</span>
          <span>Edit</span>
        </button>
      </HeadingGroup>
      <div className={`pro-pb-5 ${Style.admin_content_area}`}>
        {parse(content)}
      </div >
      
      <ModalLayout
        centered={false}
        show={menuState.showEditModal}
        handleClose={() => closeModal()}
        title={menuState?.is_edit ? "Update Terms And Condition" : ""}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle ={{root: Style.modal_root}}
      >
        <EditPolicy closeModal={() => closeModal()} refetch={refetch} />
      </ModalLayout>
    </>
  );
};

export default TermsAndCondition;
