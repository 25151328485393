import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
	updateConfig,
	createNewEntry,
} from "../../../store/slices/Clusters/clustersSlice.js";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useClusterForm = ({ refetch, closeModal }) => {
	const dispatch = useDispatch();

	const profilefileInputRef = useRef(null);

	const { is_edit, selectedId, selectedItemsDetails, creating } = useSelector((state) => state.clusters);

	

	const [imageCoverPreview, setImageCoverPreview] = useState(
		selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
	);

	const [imageBannerPreview, setImageBannerPreview] = useState(
		selectedItemsDetails !== "" ? selectedItemsDetails?.banner_image_url : ""
	);

	const [imageBasePreview, setImageBasePreview] = useState(
		selectedItemsDetails !== "" ? selectedItemsDetails?.base_image_url : ""
	);

	useEffect(() => {
		if (selectedItemsDetails !== "") {
			setImageCoverPreview(selectedItemsDetails?.image_url ?? "");
			setImageBannerPreview(selectedItemsDetails?.banner_image_url ?? "");
			setImageBasePreview(selectedItemsDetails?.base_image_url ?? "");
		}
	}, [selectedItemsDetails]);

	const handleContentChange = (editorsState) => {
		formik.setFieldValue("description", editorsState);
	  };

	const validation = Yup.object({
		name: Yup.string()
			.min(2, "The name must be at least 2 characters")
			.max(20, "max 20 characters allowed")
			.required("*Required"),
			slug: Yup.string()
			.min(2, "The slug must be at least 2 characters")
			.max(20, "max 20 characters allowed")
			.required("*Required"),
		// description: Yup.string()
		// 	.min(2, "The name must be at least 2 characters")
		// 	.max(20, "max 20 characters allowed")
		// 	.required("*Required"),
		frame_type_id: Yup.string()
			.required("*Required"),
		hang_type_id: Yup.string()
			.required("*Required"),
		finish_type_id: Yup.string()
			.required("*Required"),
		color_type_id: Yup.string()
			.required("*Required"),
		image_count: Yup.number()
		.typeError('Image Count must be a number')
			.min(1, 'Image Count must be greater than 0')
			.required("*Required"),
		discount_percentage: Yup.number()
			.typeError('Discount Percentage must be a number')
			.min(1, 'Discount Percentage must be greater than 0')
			.required("*Required"),
		price: Yup.number()
			.typeError('Price must be a number')
			.min(1, 'Price must be greater than 0')
			.required("*Required"),
		matte_price: Yup.number()
			.typeError('Matte Price must be a number')
			.min(1, 'Matte Price must be greater than 0')
			.required("*Required"),
		gloss_price: Yup.number()
			.typeError('Gloss Price must be a number')
			.min(1, 'Gloss Price must be greater than 0')
			.required("*Required"),
		canvas_price: Yup.number()
			.typeError('Canvas Price must be a number')
			.min(1, 'Canvas Price must be greater than 0')
			.required("*Required"),
		image: is_edit ? Yup.string() : Yup.string().required('*Required'),
		sku: Yup.string()
			.required("*Required"),
		banner_image: is_edit ? Yup.string() : Yup.string().required('*Required'),
		base_image: is_edit ? Yup.string() : Yup.string().required('*Required'),
	});

	const formik = useFormik({
		initialValues: {
			id: selectedItemsDetails !== ""
				? selectedItemsDetails?.id
				: "",
			name:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.name
					: "",
					slug:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.slug
					: "",
			sku:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.sku
					: "",

			frame_type_id:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.frame_type_id !== null
						? selectedItemsDetails?.frame_type_id
						: ""
					: "",
			hang_type_id:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.hang_type_id !== null
						? selectedItemsDetails?.hang_type_id
						: ""
					: "",
			color_type_id:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.color_type_id !== null
						? selectedItemsDetails?.color_type_id
						: ""
					: "",
			finish_type_id:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.finish_type_id !== null
						? selectedItemsDetails?.finish_type_id
						: ""
					: "",

			price:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.price !== null
						? selectedItemsDetails?.price
						: ""
					: "",
					matte_price:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.matte_price !== null
						? selectedItemsDetails?.matte_price
						: ""
					: "",
					gloss_price:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.gloss_price !== null
						? selectedItemsDetails?.gloss_price
						: ""
					: "",
					canvas_price:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.canvas_price !== null
						? selectedItemsDetails?.canvas_price
						: ""
					: "",
			discount_percentage:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.discount_percentage !== null
						? selectedItemsDetails?.discount_percentage
						: ""
					: "",
			image_count:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.image_count !== null
						? selectedItemsDetails?.image_count
						: ""
					: "",
			status:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.status !== null
						? selectedItemsDetails?.status
						: 0
					: 0,
			image: "",
			banner_image: "",
			base_image: "",

		},

		validationSchema: validation,
		enableReinitialize: true,

		validate: (values) => {
			const errors = {};
	  
			// Convert editor content to plain text
			const plainText = values.description.getCurrentContent().getPlainText();
	  
			// Check if the content is empty or only contains whitespace
			if (!plainText.trim()) {
			  errors.description = "*Description cannot be empty";
			}
	  
			return errors;
		  },

		onSubmit: (values, { resetForm }) => {
			const contentState = values.description.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);
			let obj = {
				id: is_edit ? selectedId : "",
				name: values.name,
				slug: values?.slug,
				description: plainText ? plainText : "",
				frame_type_id: values.frame_type_id ? values.frame_type_id : "",
				finish_type_id: values.finish_type_id ? values.finish_type_id : "",
				hang_type_id: values.hang_type_id ? values.hang_type_id : "",
				color_type_id: values.color_type_id ? values.color_type_id : "",
				price: values.price ? values.price : "",
				matte_price: values.matte_price ? values.matte_price : "",
				gloss_price: values.gloss_price ? values.gloss_price : "",
				canvas_price: values.canvas_price ? values.canvas_price : "",
				discount_percentage: values.discount_percentage ? values.discount_percentage : "",
				image_count: values.image_count ? values.image_count : "",
				status: +values.status,
				sku: values?.sku ? values?.sku : ""
			};

			if (values.image) {
				obj.image = values.image;
			}
			if (values.banner_image) {
				obj.banner_image = values.banner_image;
			}

			if (values.base_image) {
				obj.base_image = values.base_image;
			}

			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

			if (selectedItemsDetails !== "") {
				dispatch(
					createNewEntry(formData)
				).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						closeModal?.();
						refetch();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);

						toast.success(response?.payload?.message);
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
			} else {
				dispatch(createNewEntry(formData)).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						refetch();
						closeModal?.();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);

						toast.success(response?.payload?.message);
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
			}
		},
	});

// 	const defaultHtmlContent = selectedItemsDetails?.description;

//   const sanitizedHtml = DOMPurify.sanitize(defaultHtmlContent);
//   const contentState = ContentState.createFromBlockArray(
//     convertFromHTML(sanitizedHtml)
//   );

//   const editorStates = EditorState.createWithContent(contentState);

//   useEffect(() => {
//     if (!formik.values.description) {
      
//       formik.setFieldValue("description", editorStates);
//     }
//   }, [editorStates]);

useEffect(() => {
    setTimeout(() => {
      const htmlContent = selectedItemsDetails?.description?.replace(/\\/g, "");
      const sanitizedHtml = DOMPurify.sanitize(htmlContent);

      // Convert HTML to ContentState using htmlToDraft
      const contentBlock = htmlToDraft(sanitizedHtml);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      // Create an EditorState with the converted ContentState
      const editorState = EditorState.createWithContent(contentState);
      formik.setFieldValue("description", editorState);
    }, 2000);
  }, [selectedItemsDetails?.description]);

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showCreateModal = false;
			})
		);
	};


	const handleCoverImage = (e) => {
		const files = e.target.files;

		if (files?.[0]?.size / (1024 * 1024) <= 5) {
			const allowedExtensions = ["jpeg", "jpg", "png"];
			const file = e.target.files[0];
			const fileExtension = file.name.split('.').pop().toLowerCase();

			if (allowedExtensions.includes(fileExtension)) {
				formik.setFieldValue("image", e?.target?.files?.[0]);

				const reader = new FileReader();

				reader.onloadend = () => {
					setImageCoverPreview(reader.result);
				};

				if (file) {
					reader.readAsDataURL(file);
				}
			} else {
				formik.setFieldError("image", "The image must be a .jpeg, .jpg, or .png file.");
			}
		} else if (files?.[0]?.size / (1024 * 1024) > 5) {
			formik.setFieldError("image", "The image must be less than 5MB in size.");
		}
	};

	const handleBannerImage = (e) => {

		const files = e.target.files;

		if (files?.[0]?.size / (1024 * 1024) <= 5) {
			const allowedExtensions = ["jpeg", "jpg", "png"];
			const file = e.target.files[0];
			const fileExtension = file.name.split('.').pop().toLowerCase();

			if (allowedExtensions.includes(fileExtension)) {
				formik.setFieldValue("banner_image", e?.target?.files?.[0]);

				const reader = new FileReader();

				reader.onloadend = () => {
					setImageBannerPreview(reader.result);
				};

				if (file) {
					reader.readAsDataURL(file);
				}
			} else {
				formik.setFieldError("banner_image", "The banner image must be a .jpeg, .jpg, or .png file.");
			}
		} else if (files?.[0]?.size / (1024 * 1024) > 5) {
			formik.setFieldError("banner_image", "The Banner image must be less than 5MB in size.");
		}
	};

	const handleBaseImage = (e) => {

		const files = e.target.files;

		if (files?.[0]?.size / (1024 * 1024) <= 5) {
			const allowedExtensions = ["jpeg", "jpg", "png"];
			const file = e.target.files[0];
			const fileExtension = file.name.split('.').pop().toLowerCase();

			if (allowedExtensions.includes(fileExtension)) {
				formik.setFieldValue("base_image", e?.target?.files?.[0]);

				const reader = new FileReader();

				reader.onloadend = () => {
					setImageBasePreview(reader.result);
				};

				if (file) {
					reader.readAsDataURL(file);
				}
			} else {
				formik.setFieldError("base_image", "The image must be a .jpeg, .jpg, or .png file.");
			}
		} else if (files?.[0]?.size / (1024 * 1024) > 5) {
			formik.setFieldError("base_image", "The image must be less than 5MB in size.");
		}
	};



	return {
		formik,
		imageCoverPreview,
		selectedItemsDetails,
		profilefileInputRef,
		handleCoverImage,
		handleBannerImage,
		imageBannerPreview,
		handleCloseModal,
    handleContentChange,
	handleBaseImage,
	imageBasePreview,
	creating,
	is_edit
	};
};

export default useClusterForm;
