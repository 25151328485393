import { getAxiosInstance } from "../../api";


export const getAutoCompleteUserData = async (param) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/api/testimonials/users-search?name=${param.name}&user_type=${param.type}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const createOrder = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`v1/admin/order/create`,params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createItemOrder = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`v1/admin/order/edit/item-add`,params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updatePolicy = async (formData,id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put(`admin/api/privacy-and-policy/${id}`,formData);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  };

export const deleteClient = async (id) => {
    const api = await getAxiosInstance();
  
    try {
      const response = await api.delete(`admin/api/clients/${id}`);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  };

  export const getAutoCompleteData = async (searchVal) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/user-list?search=${searchVal}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };

  export const getAddressData = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/address-list`, {params: data});
      return response;
    } catch (error) {
      return error.response.data;
    }
  };


export const exportOrderReport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/admin/order/order-export`,
      body, 
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

