import useZipFileList from "./useZipFileList";
import Style from './zipFileList.module.scss';
const ZipFileList = ({ closeModal, refetch, data  }) => {
  const { bgCheckData, getRow, downloadFile } = useZipFileList({ closeModal, refetch });


  return (
    <>
      <div className="pro-p-5">
          <h6 className="pro-ttl h6 pro-mb-4">Files</h6>
          <ul className={Style.list_root}>
            {data.map((file, index) => (
              <li key={file.id} className={`${Style.list_item}`}>
                <span className={Style.count}>{`${String(index+1).padStart(2,0)}.`}</span>
                <span className="pro-pnt" onClick={() => downloadFile(file)}>
                  {file.file_name}
                   {/* : <a className="pro-pnt" onClick={() => downloadFile(file)}>{file.file_path}</a>  */}
                </span>
              </li>
            ))}
      </ul>
      </div>
    </>
  );
};

export default ZipFileList;
