import React from "react";

export const Permissions = ({
  title,
  permissions,
  module,
  uniqueID,
  defaultCheckedList,
  handleChange,
}) => {
  return (
    <div className="col-12 pro-pb-5 pro-pt-1">
      <div className="row">
        <div className="col-2">
          <h6 className="pro-ttl h6 pro-mb-0">{title}</h6>
        </div>
        <div className="col-10">
          <div className="row">
            {permissions?.map?.((permission, index) => (
              <div className="col-3" key={index}>
                {/* <div className="pro-check-box">
                  <input
                    type="checkbox"
                    id={permission.id}
                    defaultChecked={defaultCheckedList?.some?.(
                      (elm) => elm.id === permission.id
                    )}
                    name="pro-check-box1"
                    className="pro-check"
                    onChange={(e) =>
                      handleChange?.({ e, module, ...permission })
                    }
                  />
                  <label
                    htmlFor={permission.id}
                    className="pro-check-label"
                  >
                    {permission?.name || ""}
                  </label>
                </div> */}

                {/* Display sub-permissions */}
                <div className="row">

                
                {permission?.permissions?.map?.((subPermission) => (
                  <div className="col-12 pro-py-1">
                  <div key={subPermission.id} className="pro-check-box">
                    <input
                      type="checkbox"
                      id={subPermission.id}
                      defaultChecked={defaultCheckedList?.some?.(
                        (elm) => {
                          return elm.id === subPermission.id}
                      )}
                      name="pro-check-box1"
                      className="pro-check"
                      onChange={(e) =>

                        {
                          return handleChange?.({ e, ...subPermission })}
                      }
                    />
                    <label
                      htmlFor={subPermission.id}
                      className="pro-check-label"
                    >
                      {subPermission?.name || ""}
                    </label>
                  </div>
                  </div>
                ))}
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  );
};
