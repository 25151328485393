import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute/ProtectRoute";
import useRoutes from "../routes/useRoutes";

const Index = () => {
  const navigate = useNavigate();
  const { allowedEnquiresComponent,allowedStudenstComponent, allowedSettingsComponent, allowedFrameComponents, allowedActionLogsComponent,
    allowedFaculitiesComponent,
    allowedSalesComponent, allowedCoursesComponent, allowedInvoicesComponent, allowedSubAdminsComponent, allowedDashboardComponent, allowedOrdersComponents, allowedOffersComponent, allowedCMSComponent, allowedTransactionComponents, allowedCancelRequestComponents,
    allowedClusterComponents } = useRoutes();

  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate("/login");
    } else {
      
      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedEnquiresComponent?.length > 0) {
        navigate(
          `/${allowedEnquiresComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedStudenstComponent?.length > 0) {
        navigate(
          `/${allowedStudenstComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedFrameComponents?.length > 0) {
        navigate(
          `/frame/${allowedFrameComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedOffersComponent?.length > 0) {
        navigate(
          `/offers/${allowedOffersComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCMSComponent?.length > 0) {
        navigate(
          `/cms/${allowedCMSComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedOrdersComponents?.length > 0) {
        navigate(
          `/${allowedOrdersComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedClusterComponents?.length > 0) {
        navigate(
          `/${allowedClusterComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedTransactionComponents?.length > 0) {
        navigate(
          `/${allowedTransactionComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCancelRequestComponents?.length > 0) {
        navigate(
          `/${allowedCancelRequestComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCoursesComponent?.length > 0) {
        navigate(
          `/${allowedCoursesComponent?.map((item) => item?.path)?.[0]}`
        );
      } 
      else if (allowedInvoicesComponent?.length > 0) {
        navigate(
          `/${allowedInvoicesComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedSubAdminsComponent?.length > 0) {
        navigate(
          `/${allowedSubAdminsComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedActionLogsComponent?.length > 0) {
        navigate(
          `/${
            allowedActionLogsComponent?.map((item) => item?.path)?.[0]
          }`
        );
      }
      else if (allowedFaculitiesComponent?.length > 0) {
        navigate(
          `/${
            allowedFaculitiesComponent?.map((item) => item?.path)?.[0]
          }`
        );
      }
      else if (allowedSalesComponent?.length > 0) {
        navigate(
          `/${
            allowedSalesComponent?.map((item) => item?.path)?.[0]
          }`
          );
        }
       else {
        navigate(
          `/settings/${
            allowedSettingsComponent?.map((item) => item?.path)?.[0]
          }`
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ProtectRoute>
      <Outlet />
    </ProtectRoute>
  );
};

export default Index;
