import { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { getFormatedDate } from "../../utils/functions/table";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  updateConfig,
  getBranchDataByID,
  deleteBranch
} from "../../store/slices/Transactions/transactionsSlice.js";
import { useGetListDataQuery, useUpdateTableFieldsDataMutation } from "../../store/queries/transactions";
import { exportTransactionReport } from "./api.js";
import moment from "moment";

const useTransactions = (dashboard) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.transactions);

  const { showCreateModal, selctedId } = useSelector((state) => state.transactions);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [isFilterShow, setIsFilterShow] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");


  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isSuccess,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState.currentPage,
    sort_by_payment: menuState?.paymentGatewayId?.value,
    sort_by_status: menuState?.statusId?.value,
    from: menuState?.startDate
      ? menuState.startDate 
      : "",
    to: menuState?.endDate
      ? menuState.endDate
      : "",
  });

  

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);



  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateBrancheFields] = useUpdateTableFieldsDataMutation();
  

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.is_active = selectedOption.value;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/transaction/");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const handleExportClick = () => {
    exportTransactionReport({
    search: menuState.search,
    sort_by_payment: menuState?.paymentGatewayId?.value,
    sort_by_status: menuState?.statusId?.value,
    from: menuState?.startDate
      ? menuState.startDate 
      : "",
    to: menuState?.endDate
      ? menuState.endDate
      : "",
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };


  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    showImageModal,
    // imageData,
    // handleEditAction,
    // closeImageModal,
    handleActionChange,
    refetch,
    updateBrancheFields,
    handleSort,
    handleSearch,
    // handleCreateClick,
    // closeModal,
    getRow,
    handleEditClick,
    // closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleChange,
    // handleDelete,
    tableRef,
    setShowDeleteConfirm,
    setIsFilterShow,
    isFilterShow,
    handleDashboardRedirect,
    handleExportClick
    // showDeleteConfirm,
    // handleDeleteAction
  };
};

export default useTransactions;
