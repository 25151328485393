import React from "react";
import Select from "react-select";
import useItemField from "./useItemField";
import Style from "../addPrivacy.module.scss";
import { Input } from "@wac-ui-dashboard/wac_component_library";
export const ItemField = ({ formik, itmIndex, fields, isEdit }) => {
  const { handleRemoveFieldCS, OptionData, ClusterData, sizeList, getRateWithoutTax } = useItemField(
    fields,
    itmIndex,
    formik,
    isEdit
  );

  const productTypes =  [
    {
        "id": 1,
        "name": "Design"
    },
    {
        "id": 2,
        "name": "Cluster"
    }
]

  return (
    <>
      <div className={`${Style.root} pro-p-3`}>
        {formik?.values?.item?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
		<div className="row gx-2">
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Product Type*
            </label>
            <Select
              id="product_type_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched?.item?.[itmIndex]?.product_type_id &&
                formik.errors?.item?.[itmIndex]?.product_type_id &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={productTypes}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={productTypes?.filter(
                (m) => formik.values.item?.[itmIndex]?.product_type_id === m?.id
              )}
              onBlur={formik.handleBlur(`item.${itmIndex}.product_type_id`)}
              onChange={(value) => {
                formik.setFieldValue(`item.${itmIndex}.product_type_id`, value?.id || "");
              }}
            />
            {formik.touched?.item?.[itmIndex]?.product_type_id && formik.errors?.item?.[itmIndex]?.product_type_id && (
              <span className="error-text">{formik.errors?.item?.[itmIndex]?.product_type_id}</span>
            )}{" "}
          </div>
		  </div>
        <div className="row gx-2">

		{formik?.values?.item?.[itmIndex]?.product_type_id === 2 && (
			<div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Cluster Type*
            </label>
            <Select
              id="cluster_type_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched?.item?.[itmIndex]?.cluster_type_id &&
                formik.errors[`item[${itmIndex}].cluster_type_id`] &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={ClusterData?.data}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={ClusterData?.data?.filter(
                (m) => formik.values?.item?.[itmIndex]?.cluster_type_id === m?.id
              )}
              onBlur={formik.handleBlur(`item.${itmIndex}.cluster_type_id`)}
              onChange={(value) => {
                formik.setFieldValue(`item.${itmIndex}.cluster_type_id`, value?.id || "");
              }}
            />
            {formik.touched?.item?.[itmIndex]?.cluster_type_id && formik.errors[`item[${itmIndex}].cluster_type_id`] && (
              <span className="error-text">{formik.errors[`item[${itmIndex}].cluster_type_id`]}</span>
            )}{" "}
          </div>
		)}
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Frame Type*
            </label>
            <Select
              id="frame_type_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched?.item?.[itmIndex]?.frame_type_id &&
                formik.errors?.item?.[itmIndex]?.frame_type_id &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={OptionData?.data?.frame_type_list}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={OptionData?.data?.frame_type_list?.filter(
                (m) => formik.values?.item?.[itmIndex]?.frame_type_id === m?.id
              )}
              onBlur={formik.handleBlur(`item.${itmIndex}.frame_type_id`)}
              onChange={(value) => {
                formik.setFieldValue(`item.${itmIndex}.frame_type_id`, value?.id || "");
              }}
            />
            {formik.touched?.item?.[itmIndex]?.frame_type_id && formik.errors?.item?.[itmIndex]?.frame_type_id && (
              <span className="error-text">{formik.errors?.item?.[itmIndex]?.frame_type_id}</span>
            )}{" "}
          </div>

          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Colour Type*
            </label>
            <Select
              id="color_type_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched?.item?.[itmIndex]?.color_type_id &&
                formik.errors?.item?.[itmIndex]?.color_type_id &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={OptionData?.data?.color_type_list}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={OptionData?.data?.color_type_list?.filter(
                (m) => formik.values?.item?.[itmIndex]?.color_type_id === m?.id
              )}
              onBlur={formik.handleBlur(`item.${itmIndex}.color_type_id`)}
              onChange={(value) => {
                formik.setFieldValue(`item.${itmIndex}.color_type_id`, value?.id || "");
              }}
            />
            {formik.touched?.item?.[itmIndex]?.color_type_id && formik.errors?.item?.[itmIndex]?.color_type_id && (
              <span className="error-text">{formik.errors?.item?.[itmIndex]?.color_type_id}</span>
            )}{" "}
          </div>

          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Finish Type*
            </label>
            <Select
              id="finish_type_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched?.item?.[itmIndex]?.finish_type_id &&
                formik.errors?.item?.[itmIndex]?.finish_type_id &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={OptionData?.data?.finish_type_list}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={OptionData?.data?.finish_type_list?.filter(
                (m) => formik.values?.item?.[itmIndex]?.finish_type_id === m?.id
              )}
              onBlur={formik.handleBlur(`item.${itmIndex}.finish_type_id`)}
              onChange={(value) => {
                formik.setFieldValue(`item.${itmIndex}.finish_type_id`, value?.id || "");
              }}
            />
            {formik.touched?.item?.[itmIndex]?.finish_type_id && formik.errors?.item?.[itmIndex]?.finish_type_id && (
              <span className="error-text">{formik.errors?.item?.[itmIndex]?.finish_type_id}</span>
            )}{" "}
          </div>
        </div>
		{formik?.values?.item?.[itmIndex]?.product_type_id !== 2 && (
      <>
      <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Hang Type*
            </label>
            <Select
              id="hang_type_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched?.item?.[itmIndex]?.hang_type_id &&
                formik.errors[`item[${itmIndex}].hang_type_id`] &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={OptionData?.data?.hang_type_list}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={OptionData?.data?.hang_type_list?.filter(
                (m) => formik.values?.item?.[itmIndex]?.hang_type_id === m?.id
              )}
              onBlur={formik.handleBlur(`item.${itmIndex}.hang_type_id`)}
              onChange={(value) => {
                formik.setFieldValue(`item.${itmIndex}.hang_type_id`, value?.id || "");
              }}
            />
            {formik.touched?.item?.[itmIndex]?.hang_type_id && formik.errors[`item[${itmIndex}].hang_type_id`] && (
              <span className="error-text">{formik.errors[`item[${itmIndex}].hang_type_id`]}</span>
            )}{" "}
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Size Type*
            </label>
            <Select
              id="size_type_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched?.item?.[itmIndex]?.size_type_id &&
                formik.errors[`item[${itmIndex}].size_type_id`] &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={sizeList}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={sizeList?.filter(
                (m) => {
                 return formik.values?.item?.[itmIndex]?.size_type_id === m?.id}
              )}
              onBlur={formik.handleBlur(`item.${itmIndex}.size_type_id`)}
              onChange={(value) => {
                formik.setFieldValue(`item.${itmIndex}.size_type_id`, value?.id || "");
              }}
              menuPlacement="top"
            />
            {formik.touched?.item?.[itmIndex]?.size_type_id && formik.errors[`item[${itmIndex}].size_type_id`] && (
              <span className="error-text">{formik.errors[`item[${itmIndex}].size_type_id`]}</span>
            )}{" "}
          </div>
          {formik?.values?.item?.[itmIndex]?.size_type_id === "0" && (
            <>
            <div className="pro-mt-5">
            <Input
              label={"Custom size *"}
              type="text"
              id="custom_size"
              name="custom_size"
              placeholder={"Width*Height"}
              className={`pro-input lg ${
                formik.touched?.item?.[itmIndex]?.custom_size &&
                formik.errors[`item[${itmIndex}].custom_size`] &&
                " error"
              }`}
              {...formik.getFieldProps(`item.${itmIndex}.custom_size`)}
              error={
                formik.touched?.item?.[itmIndex]?.custom_size &&
                formik.errors[`item[${itmIndex}].custom_size`]
              }
              errorMessage={formik.errors[`item[${itmIndex}].custom_size`]}
            />
          </div>
          <div className="pro-mt-5">
            <Input
              label={"Custom size SKU*"}
              type="text"
              id="custom_size_sku"
              name="custom_size_sku"
              placeholder={"WidthHeight"}
              className={`pro-input lg ${
                formik.touched?.item?.[itmIndex]?.custom_size_sku &&
                formik.errors[`item[${itmIndex}].custom_size_sku`] &&
                " error"
              }`}
              {...formik.getFieldProps(`item.${itmIndex}.custom_size_sku`)}
              error={
                formik.touched?.item?.[itmIndex]?.custom_size_sku &&
                formik.errors[`item[${itmIndex}].custom_size_sku`]
              }
              errorMessage={formik.errors[`item[${itmIndex}].custom_size_sku`]}
            />
          </div>
          <div className="pro-mt-5">
            <Input
              label={"Custom size rate*"}
              type="text"
              id="custom_size_rate"
              name="custom_size_rate"
              placeholder={""}
              className={`pro-input lg ${
                formik.touched?.item?.[itmIndex]?.custom_size_rate &&
                formik.errors[`item[${itmIndex}].custom_size_rate`] &&
                " error"
              }`}
              {...formik.getFieldProps(`item.${itmIndex}.custom_size_rate`)}
              error={
                formik.touched?.item?.[itmIndex]?.custom_size_rate &&
                formik.errors[`item[${itmIndex}].custom_size_rate`]
              }
              errorMessage={formik.errors[`item[${itmIndex}].custom_size_rate`]}
              onChange={(event) =>
                getRateWithoutTax(event?.target?.value, itmIndex)
              }
            />
          </div>
          <div className="pro-mt-5">
            <Input
              label={"Custom size rate without tax*"}
              type="text"
              id="custom_size_rate_without_tax"
              name="custom_size_rate_without_tax"
              value={formik?.values?.item?.[itmIndex]?.custom_size_rate ? formik?.values?.item?.[itmIndex]?.custom_size_rate_without_tax : ""}
              placeholder={""}
              className={`pro-input lg ${
                formik.touched?.item?.[itmIndex]?.custom_size_rate_without_tax &&
                formik.errors[`item[${itmIndex}].custom_size_rate_without_tax`] &&
                " error"
              }`}
              {...formik.getFieldProps(`item.${itmIndex}.custom_size_rate_without_tax`)}
              error={
                formik.touched?.item?.[itmIndex]?.custom_size_rate_without_tax &&
                formik.errors[`item[${itmIndex}].custom_size_rate_without_tax`]
              }
              errorMessage={formik.errors[`item[${itmIndex}].custom_size_rate_without_tax`]}
              disabled={true}
            />
          </div>
            </>
          )}
      </>
			
		)}
		
      </div>
    </>
  );
};

export default ItemField;
