import {
	Button,
	Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./faqForm.module.scss";
import useFaqForm from "./useFaqForm";
import {
	capitalizeInitial,
	capitalizeOnSpace,
	limitStrLength,
} from "../../../../utils/functions/table";
import Select from "react-select";

const FaqForm = ({
	refetch,
	closeModal,
	isStickyFooter,
}) => {
	const {
		formik,
		selectedItemsDetails,
		toggleSwitch,
		isChecked,
		CategoryData
	} = useFaqForm({ refetch, closeModal });

	return (
		<div className={`pro-w-100`}>

			<div className="input-wrap pro-mb-4">
				<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
					Category*
				</label>
				<Select
					id="category_id"
					isClearable={false}
					placeholder={"Select"}
					className={`pro-input lg  ${formik.touched.category_id && formik.errors.category_id && " error"
						}`}
					classNamePrefix="pro-input"
					options={CategoryData?.data?.result}
					getOptionValue={(option) => option?.id}
					getOptionLabel={(option) => option?.category_name}
					value={CategoryData?.data?.result?.filter(
						(m) => formik.values.category_id === m?.id
					)}
					onBlur={formik.handleBlur("category_id")}
					onChange={(value) => {
						formik.setFieldValue("category_id", value?.id || null);
					}}
				/>
				{formik.touched.category_id && formik.errors.category_id && (
					<span className="error-text">{formik.errors.category_id}</span>
				)}{" "}
			</div>

			{/* <Input
				label={"Question*"}
				type="text"
				id="title
            "
				name="title
            "
				className={`pro-input lg ${formik.errors.title && formik.touched.title && "error"
					}`}
				{...formik.getFieldProps("title")}
				onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
				error={formik.errors.title && formik.touched.title}
				errorMessage={formik.errors.title}
			/> */}
			<div className="input-wrap pro-mb-4">
				<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
					Question*
				</label>
				<textarea
					id="title"
					rows={4}
					name="title"
					className={`pro-input lg ${formik.touched.title && formik.errors.title && " error"
						}`}
					{...formik.getFieldProps("title")}
				></textarea>
				{formik.touched.title && formik.errors.title && (
					<span className="error-text">{formik.errors.title}</span>
				)}
			</div>

			{/* <Input
				label={"Answer*"}
				type="text"
				id="description
            "
				name="description
            "
				className={`pro-input lg ${formik.errors.description && formik.touched.description && "error"
					}`}
				{...formik.getFieldProps("description")}
				onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
				error={formik.errors.description && formik.touched.description}
				errorMessage={formik.errors.description}
			/> */}

			<div className="input-wrap pro-mb-4">
				<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
					Answer*
				</label>
				<textarea
					id="description"
					rows={4}
					name="description"
					className={`pro-input lg ${formik.touched.description && formik.errors.description && " error"
						}`}
					{...formik.getFieldProps("description")}
				></textarea>
				{formik.touched.description && formik.errors.description && (
					<span className="error-text">{formik.errors.description}</span>
				)}
			</div>

			{/* toggle button start  */}
			<div className="pro-toggle">
				<div className="pro-toggle-box">
					<input
						id="status"
						name="status"
						type="checkbox"
						checked={Boolean(formik.values?.status) ?? false}
						onChange={(e) => { formik.setFieldValue("status", !Boolean(formik.values?.status) ?? 0); }}
					/>
					<span></span>
				</div>
				<label>Status*</label>
			</div>
			{/* toggle button end  */}

			<div
				className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
					}`}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>

				<Button
					className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
					type="submit"
					// disabled={hasUpdatePermission === true ? false : true}
					onClick={formik.handleSubmit}
				>
					{selectedItemsDetails === "" ? "Create" : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default FaqForm;
