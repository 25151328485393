import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useEnquiries = () => {
     const { checkIfActiveRoute } = useRouteUtils();
     const globalState = useSelector((state) => state.global);
     const drawerMenu = [
          {
               title: "Enquiry",
               link: "/enquiries/enquiry",
               icon: <span class="material-symbols-outlined x4">
                    contacts
               </span>,
               active: checkIfActiveRoute("/enquiries/enquiry", true),
          },
          {
               title: "Bulk Enquiry",
               link: "/enquiries/bulk-enquiry",
               icon: <span class="material-symbols-outlined x4">
                    article_shortcut
               </span>,
               active: checkIfActiveRoute("/enquiries/bulk-enquiry", true),
          },

     ];

     const drawerMenuPermission = useMemo(() => {
          let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
               // menu.name,
               ...menu.submenus?.map((side_menu) => side_menu.name),
          ]);
          return drawerMenu
               .filter((menu) => menus?.includes?.(menu.title))
               .map((menu) => {
                    return {
                         ...menu,
                    };
               });
          //eslint-disable-next-line
     }, [globalState.dashboard_permissions, location.pathname]);
     return {
          // drawerMenu: drawerMenuPermission,
          drawerMenu: drawerMenuPermission,
     };
};

export default useEnquiries;
