import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] !== false) {
        if (params?.[key] !== undefined) {
          return `${key}=${params[key]}&`;
        }
      } else {
        return "";
      }
    };
    
    const statusIdParams = params?.sort_by_status
    ? params.sort_by_status.map((id) => `sort_by_status[]=${id}`).join("&")
    : "";

    try {
      const response = await api[method](
        `${endpoint}?${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&per_page=${params?.per_page || "10"}&${getParams("search")}&page=${
          params?.page || 1
        }&${getParams("from")}&${getParams("to")}${statusIdParams}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const orders = createApi({
  reducerPath: "ordersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["orders"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/order/list`,
      }),
      providesTags: ["orders"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin/table-permission-create`,
      }),

      invalidatesTags: ["orders"],
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/order/change-status`,
      }),

      invalidatesTags: ["orders"],
    }),
    getOrderDetailData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/order/detail?order_id=${values.order_id}`,
      }),
      providesTags: ["orders"],
    }),
    getProductDetailData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/order/product-detail?product_id=${values.product_id}`,
      }),
      providesTags: ["orders"],
    }),

    updateHoldStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/order/hold/change-status`,
      }),

      invalidatesTags: ["orders"],
    }),

    updateCancelOrder: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/order/direct-cancel`,
      }),

      invalidatesTags: ["orders"],
    }),

    getClusterData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/v1/admin/order/cluster-list`,
      }),
      providesTags: ["orders"],
    }),
    getStateData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/v1/admin/order/edit/state-list`,
      }),
      providesTags: ["orders"],
    }),
    getOfferData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/v1/admin/order/edit/offer-list`,
      }),
      providesTags: ["orders"],
    }),
    getExportData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/v1/admin/order/order-export`,
      }),
      providesTags: ["orders"],
    }),
  }),
});

export const {
  useGetListDataQuery,
  useUpdateStatusMutation,
  useUpdateHoldStatusMutation,
  useUpdateTableFieldsDataMutation,
  useGetOrderDetailDataQuery,
  useGetProductDetailDataQuery,
  useGetClusterDataQuery,
  useGetStateDataQuery,
  useGetExportDataQuery,
  useUpdateCancelOrderMutation,
  useGetOfferDataQuery
} = orders;
