import {
    HeadingGroup,
    ModalLayout,
    Pagination,
    SearchFilters,
    OffCanvasLayout,
    Table,
    ConfirmationBox,
    Button,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../Global/OrderColumn";
import useClusters from "./useClusters";
import EmptyData from "../Global/EmptyData";
import Select from "react-select";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import Filter from "./Filter/index";
import Style from "./clusters.module.scss";
import { ImageCard } from "../Global/ImageCard";
import ClusterForm from "./ClusterForm";

const Clusters = ({ dashboard = false }) => {
    const {
        mainData,
        menuState,
        currentPage,
        paginationOptions,
        isFetching,
        isLoading,
        refetch,
        handlePagination,
        handlePageSize,
        handleDateChange,
        handleSort,
        handleSearch,
        getRow,
        handleEditClick,
        closeEditModal,
        showEditModal,
        handleDelete,
        showDeleteConfirm,
        setShowDeleteConfirm,
        handleDeleteAction,
        handleClearClick,
        updateBrancheFields,
        showCreateModal,
        closeModal,
        handleCreateClick,
        handleEditAction,
        showImageModal,
        closeImageModal,
        imageData,
        setIsFilterShow,
        isFilterShow,
        handleDashboardRedirect,
        handleActionChange,
        actionOptions,
        OptionData
    } = useClusters({ dashboard });


    return (
        <>
            <CommonLayout dashboard={true}>
                {!dashboard ? (
                    <HeadingGroup
                        title={"Clusters"}
                        className={`pro-mb-4`}
                        buttonTitle={"Add new"}
                        handleClick={handleCreateClick}
                    />
                ) : (
                    <></>
                )}
                <div className={`col-auto pro-pt-3 pro-pb-6`}>
                    {!dashboard ? (
                        <div className="row">
                            <div className="col">
                                <SearchFilters
                                    data={mainData?.data?.filters}
                                    onDateChange={handleDateChange}
                                    onSearchInput={handleSearch}
                                    showActions={false}
                                    handleActionClick={handleEditClick}
                                    loading={isLoading}
                                    SearchIcon={
                                        <span className="material-symbols-outlined"> search </span>
                                    }
                                    actionOptions={actionOptions?.filter((item) => item.value === 0)}
                                    onActionOptionChange={handleActionChange}
                                    //showClearFilters
                                    handleClear={handleClearClick}
                                    searchInputProps={{ value: menuState?.search }}
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {mainData?.data?.result?.length === 0 ? (
                        <EmptyData />
                    ) : (
                        <div
                            onClick={
                                dashboard ? () => handleDashboardRedirect() : () => { }
                            }
                            className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
                        >
                            <Table
                                data={mainData?.data?.result || []}
                                uniqueID={"id"}
                                editIcon={<span className="material-symbols-outlined">edit</span>}
                                deleteIcon={
                                    <span className="material-symbols-outlined">delete</span>
                                }
                                showCheckBox={true}
                                //fields={adons?.data?.fields}
                                deletable={false}
                                handleDelete={handleDelete}
                                handleEdit={handleEditAction}
                                clear={menuState.clearSelection}
                                multiSelect={false}
                                assignable={false}
                                fields={mainData?.data?.fields}
                                SortIcon={<FaSort />}
                                handleSort={handleSort}
                                getRow={getRow}
                                loading={isFetching}
                                perpage={menuState?.currentPageSize}
                            />
                        </div>
                    )}

                    {mainData?.data?.result?.length > 0 && !dashboard && (
                        <Pagination
                            currentPage={currentPage}
                            defaultValue={paginationOptions?.filter(
                                (item) => item.value === menuState?.currentPageSize
                            )}
                            totalPageCount={mainData?.data?.last_page}
                            onPageChange={handlePagination}
                            options={paginationOptions}
                            onActionChange={handlePageSize}
                            center
                        />
                    )}

                    {!dashboard ? (
                        <>
                            {" "}
                            <OffCanvasLayout
                                show={showCreateModal}
                                handleClose={closeModal}
                                // title={"Add Branch"}
                                title={menuState?.is_edit ? "Update Cluster" : "Add Cluster"}
                                closeIcon={<span className="material-symbols-outlined">close</span>}
                                backdrop="static"
                            >
                                <ClusterForm
                                    refetch={refetch}
                                    closeModal={closeModal}
                                    OptionData={OptionData?.data}
                                />
                            </OffCanvasLayout>
                            <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
                                <div className="pro-m-5">
                                    <OrderColumn
                                        title={"Choose which columns you see"}
                                        refetch={refetch}
                                        tableFields={Object.keys(mainData?.data?.fields ?? {})
                                            .reduce((filteredObj, property) => {
                                                filteredObj[property] = mainData?.data?.fields[property];
                                                return filteredObj;
                                            }, {})}
                                        moduleId={mainData?.data?.module_id}
                                        updateData={updateBrancheFields}
                                    />
                                </div>
                            </ModalLayout>

                            <ModalLayout
                                centered={false}
                                show={showDeleteConfirm}
                                handleClose={setShowDeleteConfirm}
                                backdrop="static"
                            >
                                <div className="pro-m-5">
                                    <ConfirmationBox
                                        title={`Are you sure you want to delete?`}
                                        isRight={true}
                                        cancelText={`No`}
                                        submitText={`Yes`}
                                        cancelAction={setShowDeleteConfirm}
                                        submitAction={handleDeleteAction}
                                    >
                                        ConfirmationBox
                                    </ConfirmationBox>

                                    {true && <span className="error-message">{true}</span>}
                                </div>
                            </ModalLayout>
                            <ModalLayout show={showImageModal} handleClose={closeImageModal} backdrop="static">
                                <ImageCard data={imageData} handleClose={closeImageModal} />
                            </ModalLayout>

                            <OffCanvasLayout
                                centered={true}
                                show={isFilterShow}
                                handleClose={() => {
                                    setIsFilterShow(false);
                                }}
                                title={"Filter"}
                                backdrop="static"
                                closeIcon={<span className="material-symbols-outlined">close</span>}
                            >
                                <Filter setShowform={setIsFilterShow} />
                            </OffCanvasLayout>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </CommonLayout>
        </>
    );
};

export default Clusters;
