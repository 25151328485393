import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    
    const api = await getAxiosInstance();
    const getParams = (key) => {
    
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?type=${
          params?.type
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const shippingPolicy = createApi({
  reducerPath: "shippingPolicyApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["shippingPolicy"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/policy/detail`,
      }),
      providesTags: ["shippingPolicy"],
    }),
  }),
});

export const { useGetListDataQuery } = shippingPolicy;
