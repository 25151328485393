import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useGetOrderDetailDataQuery } from "../../../store/queries/orders";

const useUserDetails = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [viewId, setViewId] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageData, setImageData] = useState("");

  const verificationState = useSelector((state) => state.membersVerification);

  const [showImageVerificationModal, setShowImageVerificationModal] =
    useState(false);

  const [showDocumentVerificationModal, setShowDocumentVerificationModal] =
    useState(false);

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetOrderDetailDataQuery({
    order_id: activeProfile,
  });

//   const hasUpdatePermission = useMemo(() => {
//     let permission = profileData?.data?.permission?.filter((p) =>
//       Object.keys(p).includes("can_update")
//     );

//     return permission?.[0]?.can_update ?? 0;
//   }, [profileData]);

//   const hasUploadsViewPermission = useMemo(() => {
//     let permission = profileData?.data?.permission?.filter((p) =>
//       Object.keys(p).includes("can_uploads_view")
//     );

//     return permission?.[0]?.can_uploads_view ?? 0;
//   }, [profileData]);

//   const hasVerifyPermission = useMemo(() => {
//     let permission = profileData?.data?.permission?.filter((p) =>
//       Object.keys(p).includes("can_view")
//     );

//     return permission?.[0]?.can_view ?? 0;
//   }, [profileData]);

  const userDetailLabel = {
    name: "Name",
    email: "Email",
    phone: "Phone",
    paymentVia: "Payment Via",

  };

  const userDetailValue = {
    name: `${
      profileData?.data?.result?.user_details?.full_name ?? "Not specified"
    }`,
    email: `${profileData?.data?.result?.user_details?.email ?? "Not specified"}`,
    phone: `${
      profileData?.data?.result?.user_details?.mobile ? `+91 ${profileData?.data?.result?.user_details?.mobile}` : "Not specified"
    }`,
    paymentVia: `${
        profileData?.data?.result?.user_details?.payment_type ?? "Not specified"
      }`,
  };

  const userDetails = Object.keys(userDetailValue).map((key) => {
    return {
      label: userDetailLabel[key],
      value:
        typeof userDetailValue[key] === "string"
          ? userDetailValue?.[key]
          : typeof userDetailValue[key] === "number"
          ? userDetailValue?.[key]
          : userDetailValue?.[key]?.name,
    };
  });

  
  const handleEduClick = () => {
    // dispatch(
    //   updateFormData((state) => {
    //     state.activeTab = "Edu";
    //     state.isEditProfile = true;
    //     state.selectedUser = activeProfile;
    //   })
    // );
    // dispatch(
    //   updateConfig((state) => {
    //     state.showCreateModal = true;
    //   })
    // );
  };

  const handleAboutClick = () => {
    // setShowModal(true);
    // dispatch(
    //   updateFormData((state) => {
    //     state.activeTab = "Basic";
    //     state.isEditProfile = true;
    //     state.selectedUser = activeProfile;
    //   })
    // );
    // dispatch(
    //   updateConfig((state) => {
    //     state.showCreateModal = true;
    //   })
    // );
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleJobClick = () => {
    // dispatch(
    //   updateFormData((state) => {
    //     state.activeTab = "Job";
    //     state.isEditProfile = true;
    //     state.selectedUser = activeProfile;
    //   })
    // );
    // dispatch(
    //   updateConfig((state) => {
    //     state.showCreateModal = true;
    //   })
    // );
  };

  const handleFamilyClick = () => {
    // dispatch(
    //   updateFormData((state) => {
    //     state.activeTab = "Family";
    //     state.isEditProfile = true;
    //     state.selectedUser = activeProfile;
    //   })
    // );
    // dispatch(
    //   updateConfig((state) => {
    //     state.showCreateModal = true;
    //   })
    // );
  };

  const handleContactClick = () => {
    // dispatch(
    //   updateFormData((state) => {
    //     state.activeTab = "Contact";
    //     state.isEditProfile = true;
    //     state.selectedUser = activeProfile;
    //   })
    // );
    // dispatch(
    //   updateConfig((state) => {
    //     state.showCreateModal = true;
    //   })
    // );
  };

//   const onProfileVerifyClick = () => {
//     setShowImageVerificationModal(true);
//     dispatch(getVerificationData({ user_id: activeProfile, upload_type: 1 }));
//   };
//   const onProfileIdVerifyClick = () => {
//     setShowDocumentVerificationModal(true);
//     dispatch(getVerificationData({ user_id: activeProfile, upload_type: 2 }));
//   };

  const closeModal = () => {
    setShowImageVerificationModal(false);
  };

  const closeIdModal = () => {
    setShowDocumentVerificationModal(() => true);
    setViewId(() => false);
  };
  const closeDocumentModal = () => {
    setShowDocumentVerificationModal(false);
  };

  const filterByStatus = (data) => {
    return data?.filter((item) => item.status !== 1 && item.status !== 2);
  };

  const ImageVerifyStatus = filterByStatus(
    profileData?.data?.data?.profile_images
  );
  const IdVerifyStatus = filterByStatus(profileData?.data?.data?.id_proofs);

  return {
    profileData: profileData?.data,
    userDetails,
    isFetching,
    isSuccess,
    isError,
    showModal,
    activeProfile,
    showImageVerificationModal,
    showDocumentVerificationModal,
    verificationState,
    ImageVerifyStatus,
    IdVerifyStatus,
    // hasUpdatePermission,
    // hasVerifyPermission,
    // hasUploadsViewPermission,
    viewId,
    imageData,
    setViewId,
    setImageData,
    closeIdModal,
    setShowDocumentVerificationModal,
    setShowImageVerificationModal,
    closeModal,
    closeDocumentModal,
    refetch,
    handleCloseModal,
    handleEduClick,
    handleAboutClick,
    handleJobClick,
    handleFamilyClick,
    handleContactClick,
  };
};

export default useUserDetails;
