import {
	Button,
	Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useStaffForm from "./useStaffForm";
import {
	capitalizeOnSpace,
} from "../../../../utils/functions/table";
import Select from 'react-select';


const StaffForm = ({
	refetch,
	closeModal,
	isStickyFooter,
	OptionData
}) => {
	const {
		formik,
		selectedItemsDetails,
		creating,
		handleShowPassword,
		showPassword,
		is_edit
	} = useStaffForm({ refetch, closeModal });

	return (
		<div className={`pro-w-100`}>
			<Input
				label={"Full Name*"}
				type="text"
				id="full_name
            "
				name="full_name
            "
				className={`pro-input lg ${formik.errors.full_name && formik.touched.full_name && "error"
					}`}
				{...formik.getFieldProps("full_name")}
				onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
				error={formik.errors.full_name && formik.touched.full_name}
				errorMessage={formik.errors.full_name}
			/>
			<Input
				label={"Email*"}
				type="text"
				id="email
            "
				name="email
            "
				className={`pro-input lg ${formik.errors.email && formik.touched.email && "error"
					}`}
				{...formik.getFieldProps("email")}
				onChange={(event) => formik.setFieldValue("email", event?.target?.value)}
				error={formik.errors.email && formik.touched.email}
				errorMessage={formik.errors.email}
			/>

			<div className="input-wrap pro-mb-4">
				<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
					Role Type*
				</label>
				<Select
					id="role_id"
					isClearable={false}
					placeholder={"Select"}
					className={`pro-input lg  ${formik.touched.role_id && formik.errors.role_id && " error"
						}`}
					classNamePrefix="pro-input"
					options={OptionData?.data}
					getOptionValue={(option) => option?.id}
					getOptionLabel={(option) => option?.name}
					value={OptionData?.data?.filter(
						(m) => formik.values.role_id === m?.id
					)}
					onBlur={formik.handleBlur("role_id")}
					onChange={(value) => {
						formik.setFieldValue("role_id", value?.id || null);
					}}
				/>
				{formik.touched.role_id && formik.errors.role_id && (
					<span className="error-text">{formik.errors.role_id}</span>
				)}{" "}
			</div>
			{!is_edit && (
				<div className="row">
					<div className="col-12">
						<div className="input-wrap p-re">
							<label
								htmlFor="password"
								className="pro-font-sm pro-mb-1 pro-fw-medium"
							>
								Password
							</label>
							<input
								type="password"
								className={`pro-input lg ${formik.errors.password &&
									formik.touched.password &&
									"error"
									}`}
								id="password"
								name="password"
								{...formik.getFieldProps("password")}
								autoComplete="new-password"
							/>
							{showPassword ? (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility_off{" "}
									</span>
								</button>
							) : (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility{" "}
									</span>
								</button>
							)}
							{formik.touched.password &&
								formik.errors.password && (
									<span className="error-text">
										{formik.errors.password}
									</span>
								)}
						</div>
					</div>
				</div>
			)}
			
			{/* toggle button start  */}
			<div className="pro-toggle pro-mt-5">
				<div className="pro-toggle-box">
					<input
						id="status"
						name="status"
						type="checkbox"
						checked={Boolean(formik.values?.status) ?? false}
						onChange={(e) => { formik.setFieldValue("status", !Boolean(formik.values?.status) ?? 0); }}
					/>
					<span></span>
				</div>

			</div>
			{/* toggle button end  */}

			<div
				className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
					}`}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>

				<Button
					className={`pro-btn pro-btn-primary ${creating ? "loading" : ""}`}
  
					type="submit"
					// disabled={hasUpdatePermission === true ? false : true}
					onClick={formik.handleSubmit}
				>
					{selectedItemsDetails === "" ? "Create" : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default StaffForm;
