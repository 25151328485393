import { combineReducers } from "@reduxjs/toolkit";
import { settings } from "./queries/settings";
import roleSlice from "./slices/Settings/roleSlice";
import globalSlice from "./slices/Global";
import enquirySlice from "./slices/Enquiries/enquirySlice";
import { enquiries } from "./queries/enquiries";
import bulkEnquirySlice from "./slices/bulkEnquiries/bulkEnquirySlice";
import { bulkEnquiries } from "./queries/bulkEnquiries";
import { email } from "./queries/email";
import emailSlice from "./slices/Email/emailSlice";
import { dashboard } from "./queries/Dashboard";
import { colors } from "./queries/colors";
import colorsSlice from "./slices/Colors/colorsSlice";
import { size } from "./queries/size";
import { hangType } from "./queries/hangType";
import sizeSlice from "./slices/Size/sizeSlice";
import hangTypeSlice from "./slices/HangType/hangTypeSlice";
import { finish } from "./queries/finish";
import finishSlice from "./slices/Finish/finishSlice";
import { type } from "./queries/type";
import typeSlice from "./slices/Type/typeSlice";
import { orders } from "./queries/orders";
import ordersSlice from "./slices/Orders/ordersSlice";
import { seasonBasedOffers } from "./queries/seasonBasedOffers";
import seasonBasedOffersSlice from "./slices/SeasonBasedOffers/seasonBasedOffersSlice";
import { nonSeasonalOffers } from "./queries/nonSeasonalOffers";
import nonSeasonOffersSlice from "./slices/NonSeasonOffers/nonSeasonOffersSlice";
import faqSlice from "./slices/FAQ/faqSlice";
import { FAQ } from "./queries/faq";
import { blog } from "./queries/blog";
import blogSlice from "./slices/Blog/blogSlice";
import { banners } from "./queries/banners";
import { testimonials } from "./queries/testimonials";
import bannersSlice from "./slices/Banners/bannersSlice";
import testimonialsSlice from "./slices/Testimonials/testimonialsSlice";
import { frameBanners } from "./queries/frameBanners";
import frameBannersSlice from "./slices/FrameBanners/frameBannersSlice";
import filterFormSlice from "./slices/filterForm/filterFormSlice";
import { filterForm } from "./queries/filterForm";
import { privacyPolicy } from "./queries/privacyPolicy";
import { termsAndCondition } from "./queries/termsAndCondition";
import { returnPolicy } from "./queries/returnPolicy";
import privacyPolicySlice from "./slices/PrivacyPolicy/privacyPolicySlice";
import termsAndConditionSlice from "./slices/TermsAndCondition/termsAndConditionSlice";
import returnPolicySlice from "./slices/ReturnPolicy/returnPolicySlice";
import { profile } from "./queries/profile";
import profileSlice from "./slices/Profile/profileSlice";
import { transactions } from "./queries/transactions";
import transactionsSlice from "./slices/Transactions/transactionsSlice";
import { cancelRequest } from "./queries/cancelRequest";
import cancelRequestSlice from "./slices/CancelRequest/cancelRequestSlice";
import { notificationRtk } from "./queries/notifications"
import notificationSlice from "./slices/Notifications/notificationSlice";
import { shippingCharge } from "./queries/shippingCharge";
import shippingChargeSlice from "./slices/ShippingCharge/shippingChargeSlice";
import { homeVideo } from "./queries/homeVideos";
import homeVideosSlice from "./slices/HomeVideos/homeVideosSlice";
import { companyDetails } from "./queries/companyDetails";
import companyDetailsSlice from "./slices/CompanyDetails/companyDetailsSlice";
import { clusters } from "./queries/clusters";
import clustersSlice from "./slices/Clusters/clustersSlice";
import { shippingPolicy } from "./queries/shippingPolicy";
import shippingPolicySlice from "./slices/ShippingPolicy/shippingPolicySlice";
import { staffs } from "./queries/staffs";
import staffsSlice from "./slices/Staffs/staffsSlice";

const appReducer = combineReducers({
  [enquiries.reducerPath]: enquiries.reducer,
  [bulkEnquiries.reducerPath]: bulkEnquiries.reducer,
  [type.reducerPath]: type.reducer,
  [colors.reducerPath]: colors.reducer,
  [settings.reducerPath]: settings.reducer,
  [email.reducerPath]: email.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [size.reducerPath]: size.reducer,
  [hangType.reducerPath]: hangType.reducer,
  [finish.reducerPath]: finish.reducer,
  [orders.reducerPath]: orders.reducer,
  [nonSeasonalOffers.reducerPath]: nonSeasonalOffers.reducer,
  [seasonBasedOffers.reducerPath]:seasonBasedOffers.reducer,
  [FAQ.reducerPath]: FAQ.reducer,
  [blog.reducerPath]: blog.reducer,
  [banners.reducerPath]: banners.reducer,
  [testimonials.reducerPath]: testimonials.reducer,
  [frameBanners.reducerPath]: frameBanners.reducer,
  [filterForm.reducerPath]: filterForm.reducer,
  [privacyPolicy.reducerPath]: privacyPolicy.reducer,
  [termsAndCondition.reducerPath]: termsAndCondition.reducer,
  [returnPolicy.reducerPath]: returnPolicy.reducer,
  [profile.reducerPath]: profile.reducer,
  [transactions.reducerPath]: transactions.reducer,
  [cancelRequest.reducerPath]: cancelRequest.reducer,
  [notificationRtk.reducerPath]: notificationRtk.reducer,
  [shippingCharge.reducerPath]: shippingCharge.reducer,
  [homeVideo.reducerPath]: homeVideo.reducer,
  [companyDetails.reducerPath]: companyDetails.reducer,
  [clusters.reducerPath]: clusters.reducer,
  [shippingPolicy.reducerPath]: shippingPolicy.reducer,
  [staffs.reducerPath]: staffs.reducer,
  staffs: staffsSlice,
  shippingPolicy: shippingPolicySlice,
  clusters: clustersSlice,
  companyDetails: companyDetailsSlice,
  homeVideo: homeVideosSlice,
  shippingCharge: shippingChargeSlice,
  cancelRequest: cancelRequestSlice,
  transactions: transactionsSlice,
  profile: profileSlice,
  privacyPolicy: privacyPolicySlice,
  termsAndCondition: termsAndConditionSlice,
  returnPolicy: returnPolicySlice,
  frameBanners: frameBannersSlice,
  banners: bannersSlice,
  testimonials: testimonialsSlice,
  blog: blogSlice,
  faq: faqSlice,
  nonSeasonOffers: nonSeasonOffersSlice, 
  seasonBasedOffers:seasonBasedOffersSlice,
  orders: ordersSlice,
  enquiry: enquirySlice,
  bulkEnquiry: bulkEnquirySlice,
  email:emailSlice,
  type: typeSlice,
  colors: colorsSlice,
  size: sizeSlice,
  hangType: hangTypeSlice,
  finish: finishSlice,
  role: roleSlice,
  global: globalSlice,
  filterForm:filterFormSlice,
  notification: notificationSlice
});

export default appReducer;
