import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateConfig,
} from "../../../store/slices/CompanyDetails/companyDetailsSlice.js";
import { useGetListDataQuery} from "../../../store/queries/companyDetails";

const useCompanydetails = (dashboard) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.companyDetails);
  const [skip, setSkip] = useState(true);

  const { showEditModal } = useSelector((state) => state.companyDetails);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery();

  // const [updateStatus] = useUpdateStatusByIdMutation();

  if(mainData) {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = mainData?.data?.result;
      })
    );
  }

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.is_edit = true;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.is_edit = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const companyDetailLabel = {
    address: "Address Line 1",
    address_line_2: "Address Line 2",
    email: "Email",
    phone: "Phone Number",
  };

  const companyDetailValue = {
    address: `${
      mainData?.data?.result?.address ?? "Not specified"
    }`,
    address_line_2: `${
      mainData?.data?.result?.address_line_2 ?? "Not specified"
    }`,
    email: `${mainData?.data?.result?.email ?? "Not specified"}`,
    phone: `+91 ${
        mainData?.data?.result?.phone ?? "Not specified"
      }`,
  };

  const companyDetails = Object.keys(companyDetailValue).map((key) => {
    return {
      label: companyDetailLabel[key],
      value:
        typeof companyDetailValue[key] === "string"
          ? companyDetailValue?.[key]
          : typeof companyDetailValue[key] === "number"
          ? companyDetailValue?.[key]
          : companyDetailValue?.[key]?.name,
    };
  });


  return {
    mainData,
    companyDetails,
    menuState,
    isFetching,
    isLoading,
    showEditModal,
    handleEditAction,
    refetch,
    closeModal,
    handleEditClick,
    closeEditModal,
  };
};

export default useCompanydetails;
