import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { Component, useEffect } from "react";
import Style from "./blogForm.module.scss";
import useBlogForm from "./useBlogForm";
import {
  capitalizeInitial,
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";

const BlogForm = ({ refetch, closeModal }) => {
  const {
    formik,
    imageCoverPreview,
    selectedItemsDetails,
    profilefileInputRef,
    CategoryData,
    handleCoverImage,
    handleContentChange,
    uploadImageCallback,
  } = useBlogForm({ refetch, closeModal });



  return (
    <div className={`pro-w-100 pro-h-100 pro-d-flex pro-flex-column`}>
      <div className="row flex-fill gx-0">
      <div className="col-md-9">
          <div className={Style.editor_container}>
            <div className="editor">
              <Editor
                wrapperClassName={Style.pro_editor_wrapper}
                editorClassName={Style.pro_editor_main}
                toolbarClassName={Style.pro_editor_toolbar}
                editorState={formik.values.content}
                onEditorStateChange={handleContentChange}
                toolbar={{
                  inline: {
                    inDropdown: true,
                  },
                  list: {
                    inDropdown: true,
                  },
                  textAlign: {
                    inDropdown: true,
                  },
                  link: {
                    inDropdown: true,
                  },
                  history: {
                    inDropdown: true,
                  },
                  image: { 
                    uploadCallback: uploadImageCallback,
                    alt: { present: true, mandatory: false },
                    previewImage: true
                   },
                }}
              ></Editor>
            </div>
            {formik.touched.content && formik.errors.content && (
              <span className={Style.error_text}>
                {formik.errors.content}
              </span>
            )}
          </div>
        </div>
        <div className={`col-md-3 pro-p-5 ${Style.right_form}`}>
          <Input
            label={"Title*"}
            type="text"
            id="title
            "
            name="title
            "
            className={`pro-input lg ${formik.errors.title && formik.touched.title && "error"
              }`}
            {...formik.getFieldProps("title")}
            onChange={(event) => formik.setFieldValue("title", event?.target?.value)}
            error={formik.errors.title && formik.touched.title}
            errorMessage={formik.errors.title}
          />

<Input
            label={"Slug*"}
            type="text"
            id="slug
            "
            name="slug
            "
            className={`pro-input lg ${formik.errors.slug && formik.touched.slug && "error"
              }`}
            {...formik.getFieldProps("slug")}
            onChange={(event) => formik.setFieldValue("slug", event?.target?.value)}
            error={formik.errors.slug && formik.touched.slug}
            errorMessage={formik.errors.slug}
          />

          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Category*
            </label>
            <Select
              id="blog_category_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg  ${formik.touched.blog_category_id &&
                formik.errors.blog_category_id &&
                " error"
                }`}
              classNamePrefix="pro-input"
              options={CategoryData?.data?.result}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.category_name}
              value={CategoryData?.data?.result?.filter(
                (m) => formik.values.blog_category_id === m?.id
              )}
              onBlur={formik.handleBlur("blog_category_id")}
              onChange={(value) => {
                formik.setFieldValue("blog_category_id", value?.id || null);
              }}
            />
            {formik.touched.blog_category_id &&
              formik.errors.blog_category_id && (
                <span className="error-text">
                  {formik.errors.blog_category_id}
                </span>
              )}{" "}
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description*
            </label>
            <textarea
              id="description"
              rows={4}
              name="description"
              className={`pro-input lg ${formik.touched.description && formik.errors.description && " error"
                }`}
              {...formik.getFieldProps("description")}
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <span className="error-text">{formik.errors.description}</span>
            )}
          </div>

          <Input
            label={"Meta Title"}
            type="text"
            id="meta_title
          "
            name="meta_title
          "
            className={`pro-input lg ${formik.errors.meta_title && formik.touched.meta_title && "error"
              }`}
            {...formik.getFieldProps("meta_title")}
            onChange={(event) => formik.setFieldValue("meta_title", event?.target?.value)}
            error={formik.errors.meta_title && formik.touched.meta_title}
            errorMessage={formik.errors.meta_title}
          />
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Meta Description
            </label>
            <textarea
              id="meta_description"
              rows={4}
              name="meta_description"
              className={`pro-input lg ${formik.touched.meta_description && formik.errors.meta_description && " error"
                }`}
              {...formik.getFieldProps("meta_description")}
            ></textarea>
            {formik.touched.description && formik.errors.meta_description && (
              <span className="error-text">{formik.errors.meta_description}</span>
            )}
          </div>

          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Image*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept=".jpeg, .jpg , .png"
                ref={profilefileInputRef}
                className={`pro-input ${formik.touched.image && formik.errors.image && " error"
                  }`}
                id="image"
                name="image"
                onBlur={formik.handleBlur("image")}
                onChange={(e) => handleCoverImage(e)}
              />
              <span className="input-drag-box">
                <IconText
                  title={
                    formik?.values?.image?.name !== undefined
                      ? limitStrLength(formik?.values?.image?.name, 30)
                      : selectedItemsDetails !== "" &&
                        selectedItemsDetails?.image
                        ? selectedItemsDetails?.image
                        : `Drop files to attach or browse`
                  }
                />
              </span>
              {formik.touched.image && formik.errors.image && (
                <span className="error-text">{formik.errors.image}</span>
              )}
            </div>
          </div>
          <div className={`col-2 pro-my-2`}>
            <div className={`${Style.root_image_inner}`}>
              <Image
                width={100}
                height={100}
                src={imageCoverPreview}
                alt={`branch - 01`}
              />
            </div>
          </div>
          {/* toggle button start  */}
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={Boolean(formik.values?.status) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "status",
                    !Boolean(formik.values?.status) ?? 0
                  );
                }}
              />
              <span></span>
            </div>
            <label>Publish*</label>
          </div>
          {/* toggle button end  */}
        </div>
      </div>

      <div className={`col-12 pro-d-flex pro-justify-end pro-py-4 pro-px-5 ${Style.footer_btn_wrap}`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default BlogForm;
