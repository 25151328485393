import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useFrames = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Type",
      link: "/frame/type",
      icon: <span className="material-symbols-outlined x4"> category </span>,
      active: checkIfActiveRoute("/frame/type", true),
    },
    {
      title: "Colors",
      link: "/frame/colors",
      icon: <span className="material-symbols-outlined x4"> colors </span>,
      active: checkIfActiveRoute("/frame/colors", true),
    },
    {
      title: "Size",
      link: "/frame/size",
      icon: <span className="material-symbols-outlined x4"> aspect_ratio </span>,
      active: checkIfActiveRoute("/frame/size", true),
    },
    {
      title: "Hang Type",
      link: "/frame/hang-type",
      icon: <span className="material-symbols-outlined x4"> hallway </span>,
      active: checkIfActiveRoute("/frame/hang-type", true),
    },
    {
      title: "Finish",
      link: "/frame/finish",
      icon: <span className="material-symbols-outlined x4"> library_add_check </span>,
      active: checkIfActiveRoute("/frame/finish", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.submenus?.map((side_menu) => side_menu.name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useFrames;
