import React from 'react';
import Style from "./frame.module.scss";
import { frameColorsMap } from "../../../../helpers/constants";
import { Image } from '@wac-ui-dashboard/wac_component_library';
import Imgix from "react-imgix";

const Frame = React.forwardRef((props, ref) => {
    const {
        color,
        type,
        frame,
        frameWidth,
        frameHeight,
        borderWidth,
        innerPadding,
        aspectRatio,
        className,
        maxWidth,
        imageSrc,
        imageAlt,
    } = props;
    
    
    let frameAspectRatio = aspectRatio ? 100 / aspectRatio : (frameHeight / frameWidth) * 100;
    let outerClass = Style.frameWrap;
    if (className) {
        outerClass += " " + className;
    }
    return (
        <>
            <div
                className={`ratio ${outerClass}`}
                style={{
                    "--frame-max-width": maxWidth,
                    "--pro-aspect-ratio": `${frameAspectRatio}%`,
                    "--frame-color": frameColorsMap[color],
                    "--frame-border-image": `url(${frame})`,
                    "--frame-image-gap": typeof Number(innerPadding) == "number" ? `${innerPadding}px` : innerPadding,
                    "--frame-border-size": typeof Number(borderWidth) == "number" ? `${borderWidth}px` : borderWidth,
                    "--swiperRatio": 100,
                }}
            >
                <div className={Style.frameBorder}>
                    <div className={`${Style.frameInner}`}>
                    <div ref={ref} className={Style.img_wrap}>
                        {/* <Image
                        alt={imageAlt ?? ""}
                        src={imageSrc ?? ""}
                        /> */}
                        {imageSrc !== undefined  && (
                            <Imgix
                              src={imageSrc}
                              htmlAttributes={{
                                alt : imageAlt
                              }}

                            />
                        )}


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default Frame
