import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    
    const api = await getAxiosInstance();
    const getParams = (key) => {
    
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("filter")}${getParams("sort_by")}is_active=${
          params?.is_active ? params["is_active"] : 0
        }&sort_order=${
          params?.sort_order || "desc"
        }&per_page=${params?.per_page || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const frameBanners = createApi({
  reducerPath: "frameBannersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["frameBanners"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/frame_banner/list`,
      }),
      providesTags: ["frameBanners"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin/table-permission-create`,
      }),

      invalidatesTags: ["frameBanners"],
    }),
    updateStatusById: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/frame_banner/status`,
      }),

      // invalidatesTags: ["frameBanners"],
    }),
  }),
});

export const { useGetListDataQuery, useUpdateTableFieldsDataMutation, useUpdateStatusByIdMutation } = frameBanners;
