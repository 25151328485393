import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  IDs: {},
  selectedItemsDetails: "",
  is_edit: false,
  productDetails: "",
  selectedStatus:"",
  autoCompleteData: [],
  addresses: [],
  showAddressEditModal: false,
  orderDetailsEditModal: false,
  orderDetails: "",
  addressDetails: "",
  showDetailsModal: false,
  files: [],
  bulkDownLoadStatus: "idle",
  currentStatusFilter: [],

};

export const getOrderDataByID = createAsyncThunk(
  "v1/admin/order/detail",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/detail?order_id=${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductDataByID = createAsyncThunk(
  "v1/admin/order/product-detail",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/product-detail?order_product_id=${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBranches = createAsyncThunk(
  "/v1/branch/store",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/branch/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddressEditData = createAsyncThunk(
  "v1/admin/order/edit/order-address-details",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/edit/order-address-details?order_id=${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateData = createAsyncThunk(
  "/v1/admin/order/edit/address-edit",
  async ({ data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/order/edit/address-edit`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getWithoutTaxRate = createAsyncThunk(
  "v1/admin/order/taxrate",
  async (rate, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/calculate-rate-without-tax?custom_size_rate=${rate}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOrderData = createAsyncThunk(
  "/v1/admin/order/edit/order-edit",
  async ({ data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/order/edit/shipping-and-coupon-change`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const synToShip = createAsyncThunk(
  "/v1/admin/shipment/shiprocket/order-push",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/shipment/shiprocket/order-push");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteOrderItems = createAsyncThunk(
  "/v1/admin/order/item-delete",
  async (data, {rejectWithValue} ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/order/edit/item-delete",data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const synToGoSwift = createAsyncThunk(
  "/v1/admin/shipment/goswift/order-push-open",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/shipment/goswift/order-push-open");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const syncToEship = createAsyncThunk(
  "/v1/admin/shipment/eshipz/order-push",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/shipment/eshipz/order-push");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFromList = createAsyncThunk(
  "/zip/file-delete",
  async (id, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/order/delete-zip-file?id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkDownload = createAsyncThunk(
  "/v1/admin/order/bulk-invoice-downlaod",
  async ( data, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/order/bulk-invoice-zip", {params: data});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const pickDownload = createAsyncThunk(
  "/v1/admin/order/bulk-picklist-downlaod",
  async ( data, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/order/pick-list", {params: data});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const GetBasicData = createAsyncThunk(
  "/v1/admin/order/basic-data",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/order/basic-data");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const ExportOrder = createAsyncThunk(
  "/v1/admin/order/order-export",
  async (id, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/order/order-export`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const zipDownload = createAsyncThunk(
  "/v1/admin/order/zips",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/order/download-list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadFileZip = createAsyncThunk(
  "/v1/admin/order/download-zips",
  async ( id, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.fetch(`/v1/admin/order/download-file?id=${id}`,{
      method: 'GET', // or 'POST', depending on your backend
      headers: {
        'Content-Type': 'application/octet-stream', // or another appropriate type
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.blob(); // Convert the binary data to a blob
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// const fetchZipFile = async () => {
//   const response = await fetch('YOUR_BACKEND_ENDPOINT', {
//     method: 'GET', // or 'POST', depending on your backend
//     headers: {
//       'Content-Type': 'application/octet-stream', // or another appropriate type
//     },
//     // Include other necessary headers or configurations
//   });

//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }

//   return await response.blob(); // Convert the binary data to a blob
// };






const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    
   builder.addCase(getOrderDataByID.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getOrderDataByID.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getOrderDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(getProductDataByID.pending, (state) => {
      state.isProductLoading = true;
      state.error = null;
    })
    .addCase(getProductDataByID.fulfilled, (state, action) => {
      state.productDetails = action.payload.data.data;
      state.isProductLoading = false;
    })
    .addCase(getProductDataByID.rejected, (state, action) => {
      state.isProductLoading = false;
      state.error = action.payload;
    });
    builder.addCase(bulkDownload.pending, (state) => {
      state.bulkDownLoadStatus = "pending";
      state.error = null;
    })
    .addCase(bulkDownload.fulfilled, (state, action) => {
      state.bulkDownLoadStatus="fulfilled";
    })
    .addCase(bulkDownload.rejected, (state, action) => {
      state.bulkDownLoadStatus ="rejected"
      state.error = action.payload;
    })
  },
  
});

export const { updateConfig } = ordersSlice.actions;

export default ordersSlice.reducer;
