import {
	HeadingGroup,
	ModalLayout,
	MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import useCompanydetails from "./useCompanydetails";
import Style from './companydetails.module.scss';
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";
import EditCompanyDetails from "./EditCompanyDetails";

const Companydetails = () => {
	const { mainData, menuState, closeModal, refetch, handleEditClick, isFetching, companyDetails } =
		useCompanydetails();

	return (
		<>
			{isFetching ? (
				<div className="pro-p-4 pro-w-100">
					<OverviewShimmer />
				</div>
			) : (
				<>
					<HeadingGroup title={"Company Details"} extraClassName={`pro-mb-5`}>
						<button
							className="pro-btn pro-btn-primary pro-items-center"
							onClick={handleEditClick}
						>
							<span class="material-symbols-outlined">edit_square</span>
							<span>Edit</span>
						</button>
					</HeadingGroup>

					<div className="pro-m-4 pro-mx-5">
										<MultiColumnTable
											minWidth={`30%`}
											data={companyDetails}
											extraClassNames={`${Style.table_wrap}`}
										/>
									</div>

					<ModalLayout
						centered={false}
						show={menuState.showEditModal}
						handleClose={() => closeModal()}
						title={menuState?.is_edit ? "Update" : ""}
						closeIcon={<span className="material-symbols-outlined">close</span>}
						backdrop="static"
						propStyle={{ root: Style.modal_root }}
					>
						<EditCompanyDetails closeModal={() => closeModal()} refetch={refetch} />
					</ModalLayout>
				</>

			)}
		</>
	);
};

export default Companydetails;
