import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../Global/OrderColumn";
import useOrders from "./useOrders";
import EmptyData from "../Global/EmptyData";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import Style from "./orders.module.scss";
import CustomDateRangePicker from "../Global/CustomDateRangePicker";
import ViewDetails from "./printInvoice/viewDetails";
import Select from "react-select";
import Confirmation from "./Confirmation";
import ViewPickListDetails from "./printPickList/viewPickListDetails";
import AddOrder from "./AddOrder";
import ZipFileList from "./ZipFileList";

const Orders = ({ dashboard = false }) => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDownload,
    showDownloadConfirm,
    handleClearClick,
    updateBrancheFields,
    showCreateModal,
    closeModal,
    handleEditAction,
    handleSelectedIds,
    handleDashboardRedirect,
    handleActionChange,
    actionOptions,
    handleSyncEship,
    handleGoSwift,
    handleDateRangeChange,
    date,
    printData,
    pickListData,
    printRef,
    basicData,
    handleActionSetStatus,
    closeConfirmModal,
    handleFuction,
    showConfirm,
    setShowConfirm,
    handleSpecificAction,
    handleCreateClick,
    handleExportClick,
    showmsg,
    selectedData,
    handleZipDownload,
    closeDetailsModal
  } = useOrders({ dashboard });


  return (
    <>
      <CommonLayout dashboard={true}>
        {!dashboard ? (
          <HeadingGroup
            title={"Orders"}
            className={`pro-mb-4`}
            buttonTitle={mainData?.data?.permissions?.can_create ? "Add new" : ""}
            handleClick={handleCreateClick}
          />
        ) : (
          <></>
        )}
        <div className={`col-auto pro-pt-3 pro-pb-6`}>
          {!dashboard ? (
            <div className="row gx-3">
              <div className="col">
                <SearchFilters
                  data={mainData?.data?.filters}
                  // onDateChange={handleDateChange}
                  onSearchInput={handleSearch}
                  showActions={false}
                  handleActionClick={handleEditClick}
                  loading={isLoading}
                  SearchIcon={
                    <span className="material-symbols-outlined"> search </span>
                  }
                  actionOptions={actionOptions?.filter(
                    (item) => item.value === 0
                  )}
                  onActionOptionChange={handleActionChange}
                  //showClearFilters
                  handleClear={handleClearClick}
                  searchInputProps={{ value: menuState?.search }}
                  dropDownFilter={
                    <>
                      <div className="col-auto">
                        <CustomDateRangePicker
                          defaultDateRange={date}
                          handleDateRangeChange={handleDateRangeChange}
                        />
                      </div>

                      <div className="col-2 dropdown-filter">
                        
                      <Select
                        id="status"
                        isMulti
                        isClearable={true}
                        placeholder={"Select Status"}
                        options={basicData}
                        // isDisabled={true}
                        getOptionValue={(option) => option?.value}
                        getOptionLabel={(option) => option?.label}
                        className={`pro-input lg multi-select`}
                        classNamePrefix="pro-input"
                        value={basicData?.filter((p) =>
                          menuState?.currentStatusFilter?.includes(p.value)
                        )}
                        onChange={(value) =>
                            handleActionSetStatus(value.map((v) => v.value, value))
                        }
                      />
                      </div>
                    </>
                  }
                />
              </div>
              <div className="col-auto pro-d-flex pro-items-start">
                {/* <button 
                  className={`pro-btn pro-items-center pro-btn-primary ${false ? "loading" : ""}`}
                  type="submit"
                  onClick={handleSyncShip}>
                  <span className="material-symbols-outlined x4" > sync </span> 
                  <span>Sync to ShipRocket</span>
                </button> */}

                <button
                  className={`pro-btn pro-items-center pro-btn-primary ${
                    false ? "loading" : ""
                  }`}
                  type="submit"
                  onClick={handleZipDownload}
                >
                  <span className="material-symbols-outlined x4">
                   download
                  </span>
                  <span>Ready To Download</span>
                </button>

                <button
                  className={`pro-btn pro-items-center pro-btn-primary pro-ms-3 ${
                    false ? "loading" : ""
                  }`}
                  type="submit"
                  onClick={handleSyncEship}
                >
                  <span className="material-symbols-outlined x4">
                    {" "}
                    sync_alt{" "}
                  </span>
                  <span>Sync To EShipz</span>
                </button>

                <button
                  className={`pro-btn pro-items-center pro-btn-primary pro-ms-3 ${
                    false ? "loading" : ""
                  }`}
                  type="submit"
                  onClick={handleGoSwift}
                >
                  <span className="material-symbols-outlined x4">
                    {" "}
                    sync_alt{" "}
                  </span>
                  <span>Sync To GoSwift</span>
                </button>

                <button
                  className={`pro-btn pro-items-center pro-btn-outline pro-ms-3 ${
                    false ? "loading" : ""
                  }`}
                  type="submit"
                  onClick={handleExportClick}
                >
                  <span className="material-symbols-outlined x4">
                    ios_share
                  </span>
                  <span>Export</span>
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* <ModalLayout show={true}>
            <PickList />
          </ModalLayout> */}
          {mainData?.data?.result?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                data={mainData?.data?.result || []}
                uniqueID={"id"}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                multiSelect={true}
                showCheckBox={dashboard ? false : true}
                handleSelect={handleSelectedIds}
                deletable={false}
                editable={false}
                handleEdit={handleEditAction}
                clear={menuState.clearSelection}
                fields={mainData?.data?.fields}
                SortIcon={!dashboard && <FaSort />}
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={menuState?.currentPageSize}
                assignable={true}
                assignIcon={<span class="material-symbols-outlined">mail</span>}
                assignText={"Download"}
                handleAssign={handleDownload}
              />
            </div>
          )}

          {mainData?.data?.result?.length > 0 && !dashboard && (
            <Pagination
              currentPage={currentPage}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === menuState?.currentPageSize
              )}
              totalPageCount={mainData?.data?.last_page}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
          {!dashboard ? (
            <>
              {" "}
              <ModalLayout
                show={showCreateModal}
                handleClose={closeModal}
                centered={false}
                backdrop="static"
                title={"Add Order"}
                closeIcon={
                  <span className="material-symbols-outlined">close</span>
                }
                propStyle={{ root: Style.modal_root }}
              >
                <AddOrder refetch={refetch} closeModal={closeModal} type={"new"}/>
              </ModalLayout>
              <ModalLayout
                show={showEditModal}
                handleClose={closeEditModal}
                backdrop="static"
              >
                <div className="pro-m-5">
                  <OrderColumn
                    title={"Choose which columns you see"}
                    refetch={refetch}
                    tableFields={Object.keys(
                      mainData?.data?.fields ?? {}
                    ).reduce((filteredObj, property) => {
                      filteredObj[property] = mainData?.data?.fields[property];
                      return filteredObj;
                    }, {})}
                    moduleId={mainData?.data?.module_id}
                    updateData={updateBrancheFields}
                  />
                </div>
              </ModalLayout>
              <ModalLayout
                centered={false}
                show={showConfirm}
                handleClose={setShowConfirm}
                backdrop="static"
              >
                <div className="pro-m-5">
                  <ConfirmationBox
                    title={
                      showmsg
                        ? `Zip File is being processed`
                        : `Are you sure want to download?`
                    }
                    isRight={true}
                    cancelText={showmsg ? `` : `No`}
                    submitText={showmsg ? "Ok" : "Yes"}
                    cancelAction={setShowConfirm}
                    submitAction={handleSpecificAction}
                  >
                    ConfirmationBox
                  </ConfirmationBox>

                  {true && <span className="error-message">{true}</span>}
                </div>
              </ModalLayout>
              <ModalLayout
                centered={false}
                show={showDownloadConfirm}
                handleClose={() => closeConfirmModal()}
                title={"Select the type"}
                closeIcon={
                  <span className="material-symbols-outlined">close</span>
                }
                backdrop="static"
              >
                <Confirmation
                  closeModal={() => closeConfirmModal()}
                  handleFunction={handleFuction}
                  refetch={refetch}
                  selectedData={selectedData}
                  count={mainData?.data?.filtered_count}
                />
              </ModalLayout>
              <ModalLayout
                centered={false}
                show={menuState.showDetailsModal}
                handleClose={() => closeDetailsModal()}
                title={"Files Ready to download"}
                closeIcon={
                  <span className="material-symbols-outlined">close</span>
                }
                backdrop="static"
                propStyle={{ root: Style.modal_root }}
              >
                <ZipFileList
                  closeModal={() => closeDetailsModal()}
                  refetch={handleZipDownload}
                  data={menuState?.files}
                />
              </ModalLayout>
            </>
          ) : (
            <></>
          )}
        </div>
      </CommonLayout>
      {printData && <ViewDetails ref={printRef} invoiceData={printData} />}

      {pickListData && (
        <ViewPickListDetails ref={printRef} invoiceData={pickListData} />
      )}
    </>
  );
};

export default Orders;
