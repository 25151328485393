import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
	updateConfig,
	createNewEntry,
	updateData,
} from "../../../../store/slices/FAQ/faqSlice.js";
import { useGetBasicDataQuery } from "../../../../store/queries/faq";

const useFaqForm = ({ refetch, closeModal }) => {
	const dispatch = useDispatch();

	const profilefileInputRef = useRef(null);

	const { is_edit, selectedId, selectedItemsDetails } = useSelector((state) => state.faq);

	const { data: CategoryData = {}, isSuccess: isOptionDatas } = useGetBasicDataQuery({});

	const validation = Yup.object({
		title: Yup.string()
			.min(2, "The title must be at least 2 characters")
			.required("*Required"),
		description: Yup.string()
			.min(2, "The description must be at least 2 characters")
			.required("*Required"),
		category_id: Yup.string()
			.required("*Required"),

	});

	const formik = useFormik({
		initialValues: {
			category_id: selectedItemsDetails !== ""
				? selectedItemsDetails.faq_category_id
				: "",
			title:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.title
					: "",
			description:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.description
					: "",
			status:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.status !== null
						? selectedItemsDetails?.status
						: 0
					: 0,

		},

		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			let obj = {
				id: is_edit ? selectedId : "",
				category_id: values?.category_id ? values?.category_id : "",
				title: values.title,
				description: values.description ? values.description : "",
				status: +values.status,
			};

			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

			if (selectedItemsDetails !== "") {
				dispatch(
					updateData({ data: formData })
				).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						closeModal?.();
						refetch();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);

						toast.success(response?.payload?.message);
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
			} else {
				dispatch(createNewEntry(formData)).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						refetch();
						closeModal?.();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);

						toast.success(response?.payload?.message);
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
			}
		},
	});

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showCreateModal = false;
			})
		);
	};



	return {
		formik,
		selectedItemsDetails,
		profilefileInputRef,
		handleCloseModal,
		CategoryData
	};
};

export default useFaqForm;
