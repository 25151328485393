import React from 'react'

function ShippingDetails() {
  return (
    <div>
      ShippingDetails
    </div>
  )
}

export default ShippingDetails
