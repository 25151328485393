import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useStaffs from "./useStaffs";
import EmptyData from "../../Global/EmptyData";
import { ImageCard } from "../../Global/ImageCard";
import StaffForm from "./StaffForm";

const Staffs = () => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleClearClick,
    updateTableFields,
    showCreateModal,
    closeModal,
    handleCreateClick,
    handleEditAction,
    showImageModal,
    closeImageModal,
    imageData,
    actionOptions,
    handleActionChange,
    OptionData
  } = useStaffs();

  return (
    <>
      <HeadingGroup
        title={"Staffs"}
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={mainData?.data?.filters}
              onDateChange={handleDateChange}
              onSearchInput={handleSearch}
              showActions={false}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              //showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: menuState?.search }}
            />
          </div>
        </div>

        {mainData?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.result || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={true}
            //fields={adons?.data?.fields}
            handleEdit={handleEditAction}
            deletable={false}
            clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
          />
        )}

        {mainData?.data?.result?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          // title={"Add Branch"}
          title={menuState?.is_edit ? "Update Staff" : "Add Staff"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <StaffForm
            refetch={refetch}
            closeModal={closeModal}
            OptionData={OptionData}
          />
        </OffCanvasLayout>
        <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {})
                .reduce((filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                }, {})}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showImageModal} handleClose={closeImageModal} backdrop="static">
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>

        
      </div>
    </>
  );
};

export default Staffs;
