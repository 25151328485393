import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  transferNote: "",
  IDs: {},
  selectedId:"",
  selectedItemsDetails:"",
  is_active: 1,
  phoneVerified: {
    verified: false,
    status: false,
  },
  is_edit: false,
};

export const getSizeDataByID = createAsyncThunk(
  "v1/admin/size-type/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/size-type/edit?id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createSize = createAsyncThunk(
  "/v1/admin/size-type/create",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/size-type/create", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSize = createAsyncThunk(
  "v1/admin/size-type/update",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`v1/admin/size-type/update`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSize = createAsyncThunk(
  "/v1/admin/size-type/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(`/v1/admin/size-type/delete?id=${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sizeSlice = createSlice({
  name: "size",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    
   builder.addCase(getSizeDataByID.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getSizeDataByID.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getSizeDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    
  },
  
});

export const { updateConfig } = sizeSlice.actions;

export default sizeSlice.reducer;
