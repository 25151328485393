import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./clusterForm.module.scss";
import useClusterForm from "./useClusterForm";
import {
  capitalizeInitial,
  capitalizeOnSpace,
  limitStrLength,
} from "../../../utils/functions/table";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";

const ClusterForm = ({ refetch, closeModal, isStickyFooter, OptionData }) => {
  const {
    formik,
    imageCoverPreview,
    imageThumbnailPreview,
    profilefileInputRef,
    handleThumbnailImage,
    handleCoverImage,
    Options,
    setOfferType,
    selectedItemsDetails,
    handleBannerImage,
    imageBannerPreview,
    handleContentChange,
    handleBaseImage,
    imageBasePreview,
    creating,
    is_edit,
  } = useClusterForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name
            "
        name="name
            "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />
      <Input
        label={"Slug*"}
        type="text"
        id="slug
            "
        name="slug
            "
        classslug={`pro-input lg ${
          formik.errors.slug && formik.touched.slug && "error"
        }`}
        {...formik.getFieldProps("slug")}
        onChange={(event) => formik.setFieldValue("slug", event?.target?.value)}
        error={formik.errors.slug && formik.touched.slug}
        errorMessage={formik.errors.slug}
      />
      <div className="col pro-mb-4">
          <div className="input-wrap">
      <div className={Style.editor_container}>
        <Editor
          wrapperClassName={Style.pro_editor_wrapper}
          editorClassName={Style.pro_editor_main}
          toolbarClassName={Style.pro_editor_toolbar}
          editorState={formik.values.description}
          readOnly={false}
          onEditorStateChange={handleContentChange}
          // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
          toolbar={{
            options: [
              "blockType",
              "inline",
              "list",
              "textAlign",
              "fontSize",
              "fontFamily",
            ],
            blockType: {
              inDropdown: true,
              className: Style.dropdown_wrapper,
              dropdownClassName: Style.dropdown_menu,
            },
          }}
        />
        {formik.touched.description && formik.errors.description && (
          <span className={Style.error_text}>{formik.errors.description}</span>
        )}{" "}
      </div>
      </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Frame Type*
        </label>
        <Select
          id="frame_type_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.frame_type_id &&
            formik.errors.frame_type_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={OptionData?.frame_type_list}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={OptionData?.frame_type_list?.filter(
            (m) => formik.values.frame_type_id === m?.id
          )}
          onBlur={formik.handleBlur("frame_type_id")}
          onChange={(value) => {
            formik.setFieldValue("frame_type_id", value?.id || null);
          }}
          isDisabled={is_edit ? true : false}
        />
        {formik.touched.frame_type_id && formik.errors.frame_type_id && (
          <span className="error-text">{formik.errors.frame_type_id}</span>
        )}{" "}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Colour Type*
        </label>
        <Select
          id="color_type_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.color_type_id &&
            formik.errors.color_type_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={OptionData?.color_type_list}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={OptionData?.color_type_list?.filter(
            (m) => formik.values.color_type_id === m?.id
          )}
          onBlur={formik.handleBlur("color_type_id")}
          onChange={(value) => {
            formik.setFieldValue("color_type_id", value?.id || null);
          }}
          isDisabled={is_edit ? true : false}
        />
        {formik.touched.color_type_id && formik.errors.color_type_id && (
          <span className="error-text">{formik.errors.color_type_id}</span>
        )}{" "}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Finish Type*
        </label>
        <Select
          id="finish_type_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.finish_type_id &&
            formik.errors.finish_type_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={OptionData?.finish_type_list}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={OptionData?.finish_type_list?.filter(
            (m) => formik.values.finish_type_id === m?.id
          )}
          onBlur={formik.handleBlur("finish_type_id")}
          onChange={(value) => {
            formik.setFieldValue("finish_type_id", value?.id || null);
          }}
          isDisabled={is_edit ? true : false}
        />
        {formik.touched.finish_type_id && formik.errors.finish_type_id && (
          <span className="error-text">{formik.errors.finish_type_id}</span>
        )}{" "}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Hang Type*
        </label>
        <Select
          id="hang_type_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.hang_type_id &&
            formik.errors.hang_type_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={OptionData?.hang_type_list}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={OptionData?.hang_type_list?.filter(
            (m) => formik.values.hang_type_id === m?.id
          )}
          onBlur={formik.handleBlur("hang_type_id")}
          onChange={(value) => {
            formik.setFieldValue("hang_type_id", value?.id || null);
          }}
          isDisabled={is_edit ? true : false}
        />
        {formik.touched.hang_type_id && formik.errors.hang_type_id && (
          <span className="error-text">{formik.errors.hang_type_id}</span>
        )}{" "}
      </div>

      <Input
        label={"SKU*"}
        type="text"
        id="sku
            "
        name="sku
            "
        className={`pro-input lg ${
          formik.errors.sku && formik.touched.sku && "error"
        }`}
        {...formik.getFieldProps("sku")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.sku && formik.touched.sku}
        errorMessage={formik.errors.sku}
      />

      <Input
        label={"Normal Price*"}
        type="text"
        id="price
            "
        name="price
            "
        className={`pro-input lg ${
          formik.errors.price && formik.touched.price && "error"
        }`}
        {...formik.getFieldProps("price")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.price && formik.touched.price}
        errorMessage={formik.errors.price}
      />

<Input
        label={"Matte Price*"}
        type="text"
        id="matte_price
            "
        name="matte_price
            "
        className={`pro-input lg ${
          formik.errors.matte_price && formik.touched.matte_price && "error"
        }`}
        {...formik.getFieldProps("matte_price")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.matte_price && formik.touched.matte_price}
        errorMessage={formik.errors.matte_price}
      />

<Input
        label={"Gloss Price*"}
        type="text"
        id="gloss_price
            "
        name="gloss_price
            "
        className={`pro-input lg ${
          formik.errors.gloss_price && formik.touched.gloss_price && "error"
        }`}
        {...formik.getFieldProps("gloss_price")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.gloss_price && formik.touched.gloss_price}
        errorMessage={formik.errors.gloss_price}
      />

<Input
        label={"Canvas Price*"}
        type="text"
        id="canvas_price
            "
        name="canvas_price
            "
        className={`pro-input lg ${
          formik.errors.canvas_price && formik.touched.canvas_price && "error"
        }`}
        {...formik.getFieldProps("canvas_price")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.canvas_price && formik.touched.canvas_price}
        errorMessage={formik.errors.canvas_price}
      />

      <Input
        label={"Discount Percentage*"}
        type="text"
        id="discount_percentage
            "
        name="discount_percentage
            "
        className={`pro-input lg ${
          formik.errors.discount_percentage &&
          formik.touched.discount_percentage &&
          "error"
        }`}
        {...formik.getFieldProps("discount_percentage")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={
          formik.errors.discount_percentage &&
          formik.touched.discount_percentage
        }
        errorMessage={formik.errors.discount_percentage}
        // disabled={is_edit ? true : false}
      />

      <Input
        label={"Image Count*"}
        type="text"
        id="image_count
            "
        name="image_count
            "
        className={`pro-input lg ${
          formik.errors.image_count && formik.touched.image_count && "error"
        }`}
        {...formik.getFieldProps("image_count")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.image_count && formik.touched.image_count}
        errorMessage={formik.errors.image_count}
        disabled={is_edit ? true : false}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleCoverImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name !== undefined
                  ? limitStrLength(formik?.values?.image?.name, 30)
                  : selectedItemsDetails !== "" && selectedItemsDetails?.image
                  ? selectedItemsDetails?.image
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`branch - 01`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Banner Image*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.banner_image &&
              formik.errors.banner_image &&
              " error"
            }`}
            id="banner_image"
            name="banner_image"
            onBlur={formik.handleBlur("banner_image")}
            onChange={(e) => handleBannerImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.banner_image?.name !== undefined
                  ? limitStrLength(formik?.values?.banner_image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.banner_image
                  ? selectedItemsDetails?.banner_image
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.banner_image && formik.errors.banner_image && (
            <span className="error-text">{formik.errors.banner_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageBannerPreview}
            alt={`branch - 01`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image for Dimensions*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.base_image && formik.errors.base_image && " error"
            }`}
            id="base_image"
            name="base_image"
            onBlur={formik.handleBlur("base_image")}
            onChange={(e) => handleBaseImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.base_image?.name !== undefined
                  ? limitStrLength(formik?.values?.base_image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.base_image
                  ? selectedItemsDetails?.base_image
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.base_image && formik.errors.base_image && (
            <span className="error-text">{formik.errors.base_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageBasePreview}
            alt={`branch - 01`}
          />
        </div>
      </div>
      {/* toggle button start  */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? 0
              );
            }}
          />
          <span></span>
        </div>
      </div>
      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn pro-btn-primary ${creating ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default ClusterForm;
