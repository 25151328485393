import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useGetOrderDetailDataQuery } from "../../../store/queries/orders";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

const useOrderDetails = () => {
	const dispatch = useDispatch();
	const [params] = useSearchParams();
	const printRef = useRef();

	const productState = useSelector((state) => state.orders);

	const activeProfile =
		params.get("active") ?? sessionStorage.getItem("active");

	const {
		isFetching,
		isSuccess,
		isError,
		refetch,
	} = useGetOrderDetailDataQuery({
		order_id: activeProfile,
	});

	const [sizeName, setSizeName] = useState()

	const handleDownloadClick = () => {
		// Function to download a blob as a file
		const downloadBlob = (blob, filename) => {
		  const blobUrl = URL.createObjectURL(blob);
	  
		  const downloadLink = document.createElement('a');
		  downloadLink.href = blobUrl;
		  downloadLink.download = filename || 'downloaded_image';
		  document.body.appendChild(downloadLink);
		  downloadLink.click();
		  document.body.removeChild(downloadLink);
	  
		  URL.revokeObjectURL(blobUrl);
		};
	  
		// Fetch the original image
		const fetchOriginalImage = fetch(productState?.productDetails?.product_image)
		  .then((response) => response.blob())
		  .then((blob) => downloadBlob(blob, productState?.productDetails?.product_name));
	  
		// Fetch the cropped image
		let fetchCroppedImage = "";
		if(productState?.productDetails?.product_cropped_image !== null) {
			fetchCroppedImage = fetch(productState?.productDetails?.product_cropped_image)
			.then((response) => response.blob())
			.then((blob) => downloadBlob(blob, 'cropped_image'));
		}
		
	  
		// Handle errors for both fetches
		Promise.allSettled([fetchOriginalImage, fetchCroppedImage])
		  .then((results) => {
			results.forEach((result) => {
			  if (result.status === 'rejected') {
				toast.error('Error downloading image:', result.reason);
			  }
			});
		  });
	  };
	  

	const handlePrintClick = useReactToPrint({
		content: () => printRef.current,
		
	});

	const actions = [
		{
			label: "Download",
			handleClick: handleDownloadClick,
			icon: <span className="material-symbols-outlined"> cloud_download </span>,
		},
		{
			label: "Print",
			handleClick: handlePrintClick,
			icon: <span className="material-symbols-outlined"> print </span>,
		},
	];

	const handleImageDownloadClick = (cropped_imgUrl, imageUrl, imageName) => {
		// Function to download a blob as a file
		const downloadBlob = (blob, filename) => {
		  const blobUrl = URL.createObjectURL(blob);
	  
		  const downloadLink = document.createElement('a');
		  downloadLink.href = blobUrl;
		  downloadLink.download = filename || 'downloaded_image';
		  document.body.appendChild(downloadLink);
		  downloadLink.click();
		  document.body.removeChild(downloadLink);
	  
		  URL.revokeObjectURL(blobUrl);
		};
	  
		// Fetch the original image
		const fetchOriginalImage = fetch(imageUrl)
		  .then((response) => response.blob())
		  .then((blob) => downloadBlob(blob, imageName));
	  
		// Fetch the cropped image
		let fetchCroppedImage = "";
		if(cropped_imgUrl !== null) {
			fetchCroppedImage = fetch(cropped_imgUrl)
			.then((response) => response.blob())
			.then((blob) => downloadBlob(blob, `cropped_${imageName || 'downloaded_image'}`));
		}
		
	  
		// Handle errors for both fetches
		Promise.allSettled([fetchOriginalImage, fetchCroppedImage])
		  .then((results) => {
			results.forEach((result) => {
			  if (result.status === 'rejected') {
				toast.error('Error downloading image:', result.reason);
			  }
			});
		  });
	  };
	  

	const getName = (index) => {
		let size_name = `${productState?.productDetails?.cluster_details[index]?.width}*${productState?.productDetails?.cluster_details[index]?.height}`;
		setSizeName(size_name);
	}

	useEffect(() => {
		if (productState?.productDetails?.cluster_details) {
			getName(0);
		}
	}, [productState?.productDetails?.cluster_details]);



	return {
		productData: productState?.productDetails,
		isProdFetching: productState?.isProductLoading,
		isFetching,
		isSuccess,
		isError,
		activeProfile,
		refetch,
		actions,
		printRef,
		handleImageDownloadClick,
		moment,
		sizeName,
		getName

	};
};

export default useOrderDetails;
