import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";
import { useDispatch } from "react-redux";
import Orders from "../../Orders";
import { updateConfig } from "../../../store/slices/Orders/ordersSlice";
import { updateConfig as PaymentUdateConfig } from "../../../store/slices/Transactions/transactionsSlice";
import Transactions from "../../Transactions";


const useDashboardListing = () => {
  const dispatch = useDispatch();
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      
      setListingType(Object.keys(dashboardData?.data || {})?.[0] ?? "");
    }
      handleTotalOrders();

    //eslint-disable-next-line
  }, [isSuccess]);


  const handleTotalOrders = () => {
    setListingType("TotalOrders");
    let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    dispatch(
      updateConfig((state) => {
        // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
        state.startDate = tempdate;
        state.endDate = currentDate;
      })
    );
  };
  const handleNewOrders = () => {
    setListingType("NewOrders");
    dispatch(
      updateConfig((state) => {
        state.startDate = firstDayOfMonth;
        state.endDate = currentDate;
      })
    );
  };

  const handleTodayOrders = () => {
    setListingType("TodayOrders");
    dispatch(
      updateConfig((state) => {
        state.startDate = currentDate;
        state.endDate = currentDate;
      })
    );
  };
  const handlePaymentHistory = () => {
    setListingType("PaymentHistory");
    dispatch(
      PaymentUdateConfig((state) => {
        state.startDate = formatDate(firstDayOfMonth);
        state.endDate = formatDate(currentDate);
        state.statusId = {
          label: "COMPLETED",
          value: 1,
        };
      })
    );
  };

  let object = {
    TotalOrders: handleTotalOrders,
    NewOrders: handleNewOrders,
    TodayOrders: handleTodayOrders,
    PaymentHistory: handlePaymentHistory,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    
    TotalOrders: {
      label: "TotalOrders",
      title: "Total Orders",
      icon: "person_raised_hand",
      handleClick,
      prev: "previous_year",
      component: <Orders dashboard={true} />,
    },
    NewOrders: {
      label: "NewOrders",
      title: "New Orders",
      icon: "person",
      handleClick,
      prev: "previous_month",
      component: <Orders dashboard={true} />,
    },
    TodayOrders: {
      label: "TodayOrders",
      title: "Today Orders",
      icon: "person",
      handleClick,
      prev: "yesterday",
      component: <Orders dashboard={true} />,
    },
    PaymentHistory: {
      label: "PaymentHistory",
      title: "Payment History",
      icon: "savings",
      handleClick,
      prev: "previous_month",
      component: <Transactions dashboard={true} />,
    },
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
  };
};

export default useDashboardListing;
