import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  paymentGatewayId: "", // New variable for franchise ID
  statusId: "", // New variable for active status
  startDate: "", // New variable for start date
  endDate: ""
};


export const createNewEntry = createAsyncThunk(
  "/v1/admin/staff/create",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/staff/create", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getEditDataByID = createAsyncThunk(
  "v1/admin/staff/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin/staff/edit?id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEntry = createAsyncThunk(
  "/v1/admin/staff/update",
  async (param, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/staff/update`, param);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const staffsSlice = createSlice({
  name: "staffs",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.paymentGatewayId = action.payload.paymentGateway;
      state.statusId = action.payload.statusId;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    resetExtraFilters: (state, action) => {
      state.paymentGatewayId = "";
      state.statusId = "";
      state.startDate = "";
      state.endDate = "";
    },
  },
  extraReducers: (builder) => {
    
    builder.addCase(getEditDataByID.pending, (state) => {
       state.isLoading = true;
       state.error = null;
     })
     .addCase(getEditDataByID.fulfilled, (state, action) => {
       state.selectedItemsDetails = action.payload.data.data;
     })
     .addCase(getEditDataByID.rejected, (state, action) => {
       state.isLoading = false;
       state.error = action.payload;
     })
     .addCase(createNewEntry.pending, (state) => {
      state.creating = true;
      state.error = null;
    })
    .addCase(createNewEntry.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
      state.creating = false;

    })
    .addCase(createNewEntry.rejected, (state, action) => {
      state.creating = false;
      state.error = action.payload;
    });
     
   },
  
});

export const { 
  updateConfig,
  resetExtraFilters,
  setStoreFormValues,
 } = staffsSlice.actions;

export default staffsSlice.reducer;
