import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  activeFilter: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  scrollStatusPage:{},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  is_edit: false,
  streamData: {},
  userData: {},
  selectedItemsDetails: {},
  selectedItemData: {},
  showCreateModal: false,
  showNotesCk: false,
  viewNotes: false,
  showMailCk: false,
  initial:true
};

const bulkEnquirySlice = createSlice({
     name: "bulkEnquiry",
     initialState,
     reducers: {
       updateConfig: (state, action) => {
         action.payload(state);
       },
     },

   });
   
   export const { updateConfig } = bulkEnquirySlice.actions;
   
   export default bulkEnquirySlice.reducer;