import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showEditModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
};

export const updateData = createAsyncThunk(
  "/v1/admin/settings/update-company-deatils",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/settings/update-company-deatils`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  
});

export const { updateConfig } = companyDetailsSlice.actions;

export default companyDetailsSlice.reducer;
