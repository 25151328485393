import { useEffect } from "react";
import { useGetBasicDataQuery } from "../../../../store/queries/clusters";
import { useGetClusterDataQuery } from "../../../../store/queries/orders";
import { getWithoutTaxRate } from "../../../../store/slices/Orders/ordersSlice";
import { useDispatch } from "react-redux";

export const useItemField = (
  fields,
  itmIndex,
  formik,
  basicData,
  tempIds,
  tempPIds,
  isEdit
) => {
  const dispatch = useDispatch();
  
  const { data: OptionData = {} } = useGetBasicDataQuery();
  const { data: ClusterData = {} } = useGetClusterDataQuery();
  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.item?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("item", data);
  };

  const customSize = {
    "id": "0",
    "name": "Custom Size",
    "image_url": "",
    "thumbnail_url": ""
};
let sizeList = OptionData?.data?.size_type_list

if (sizeList) {
  sizeList = [
    customSize,
    ...sizeList
  ]; 
}


const getRateWithoutTax = (value,index) => {
  if(+value === 0){
    formik.setFieldValue(`item[${index}].custom_size_rate`, +value);
    formik.setFieldValue(`item[${index}].custom_size_rate_without_tax`, 0);
  }
  else if(+value > 0) {
    formik.setFieldValue(`item[${index}].custom_size_rate`, +value)
    dispatch(getWithoutTaxRate(value)).then((response) => {
      if (response?.payload?.data?.data) {
          formik.setFieldValue(`item[${index}].custom_size_rate_without_tax`, response?.payload?.data?.data?.custom_size_rate_without_tax);
        
      }
    });
  }
}


  return {
    handleRemoveFieldCS,
    OptionData,
    ClusterData,
    sizeList,
    getRateWithoutTax
  };
};

export default useItemField;
