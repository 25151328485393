import { useSelector } from "react-redux";
import { useMemo } from "react";

import Roles from "../components/Configure/Roles";

import Appearance from "../components/Configure/Appearance";
import Enquiry from "../components/Enquires/Enquiry";
import BulkEnquiry from "../components/Enquires/BulkEnquiry";
import Email from "../components/Configure/Email";
import Dashboard from "../components/Dashboard";
import Colors from "../components/Frames/colors";
import Size from "../components/Frames/size";
import HangType from "../components/Frames/hangType";
import Finish from "../components/Frames/finish";
import Type from "../components/Frames/type";
import Orders from "../components/Orders";
import SeasonBasedOffers from "../components/Offers/seasonBasedOffers";
import Faq from "../components/CMS/faq";
import Blog from "../components/CMS/blog";
import Banners from "../components/CMS/banners";
import Testimonials from "../components/CMS/testimonials";
import FrameBanners from "../components/CMS/frameBanners";
import NonSeasonBasedOffers from "../components/Offers/nonSeasonBasedOffers";
import PrivacyPolicy from "../components/CMS/privacyPolicy";
import ReturnPolicy from "../components/CMS/returnPolicy";
import TermsAndCondition from "../components/CMS/termsAndCondition";
import Transactions from "../components/Transactions";
import CancelRequest from "../components/CancelRequest";
import ShippingCharge from "../components/Configure/ShippingCharge";
import Clusters from "../components/Clusters";
import ShippingPolicy from "../components/CMS/shippingPolicy";
import Staffs from "../components/Configure/Staffs";


const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const savedBranchPermissions = JSON.parse(localStorage.getItem('dashboard_permissions')) || [];



  const EnquiresComponents = [
    {
      component: <Enquiry />,
      permission: "Enquiry",
      path: "enquiry",
    },
    {
      component: <BulkEnquiry/>,
      permission: "Bulk Enquiry",
      path: "bulk-enquiry",
    },
  ];

  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];

  const OrderComponent = [
    {
      component: <Orders />,
      permission: "Orders",
      path: "orders",
    },
  ];

  const ClusterComponent = [
    {
      component: <Clusters />,
      permission: "Cluster",
      path: "clusters",
    },
  ];

  const TransactionComponent = [
    {
      component: <Transactions />,
      permission: "Transaction",
      path: "transaction",
    },
  ];

  const CancelRequestComponent = [
    {
      component: <CancelRequest />,
      permission: "Cancellation Request",
      path: "cancelation-request",
    },
  ];


  const FrameComponents = [
    {
      component: <Type />,
      permission: "Type",
      path: "type",
    },
    {
      component: <Colors />,
      permission: "Colors",
      path: "colors",
    },
    {
      component: <Size />,
      permission: "Size",
      path: "size",
    },
    {
      component: <HangType />,
      permission: "Hang Type",
      path: "hang-type",
    },
    {
      component: <Finish />,
      permission: "Finish",
      path: "finish",
    },
  ];

  const SettingsComponents = [
    {
      component: <ShippingCharge />,
      permission: "Shipping Charge",
      path: "shipping-charge",
    },
    {
      component: <Roles />,
      permission: "Roles",
      path: "role",
    },
    {
      component: <Staffs />,
      permission: "Staff",
      path: "staffs",
    },

    {
      component: <Appearance />,
      permission: "Appearance",
      path: "appearance",
    },
  ];

  const OffersComponents = [
    {
      component: <NonSeasonBasedOffers />,
      permission: "Non Season Offers",
      path: "non-season-offers",
    },
    {
      component: <SeasonBasedOffers />,
      permission: "Season Based Offers",
      path: "season-based-offers",
    },
  ];

  const CMSComponents = [
    {
      component: <Faq />,
      permission: "FAQ",
      path: "faq",
    },
    {
      component: <Blog />,
      permission: "Blogs",
      path: "blog",
    },
    {
      component: <Banners />,
      permission: "Home Banners",
      path: "banners",
    },
    {
      component: <FrameBanners />,
      permission: "Frame Banners",
      path: "frame-banners",
    },
    {
      component: <Testimonials />,
      permission: "Testimonials",
      path: "testimonials",
    },
    {
      component: <PrivacyPolicy />,
      permission: "Privacy Policy",
      path: "privacy-policy",
    },
    {
      component: <ShippingPolicy />,
      permission: "Shipping Policy",
      path: "shipping-policy",
    },
    {
      component: <ReturnPolicy />,
      permission: "Return Policy",
      path: "return-policy",
    },
    {
      component: <TermsAndCondition />,
      permission: "Terms And Condition",
      path: "terms-and-condition",
    },
  ];



  const drawerMenuPermission = useMemo(() => {
    let menus = savedBranchPermissions?.flatMap?.((menu) => 
    
    {
      return[
      ...menu.submenus?.map((side_menu) => side_menu.name),
    ]
  }
    );
    if (!menus) {
      menus = [];
    }
    menus = [...menus, 
      "Appearance"
      // "Dashboard"
    ];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {

    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }




  const allowedDashboardComponent = DashboardComponent.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedEnquiresComponent = EnquiresComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedFrameComponents = FrameComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedOrdersComponents = OrderComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedClusterComponents = ClusterComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedTransactionComponents = TransactionComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCancelRequestComponents = CancelRequestComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedOffersComponent = OffersComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCMSComponent = CMSComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
 
  const allowedSettingsComponent = SettingsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );


  return {
    allowedDashboardComponent,
    allowedEnquiresComponent,
    allowedSettingsComponent,
    allowedFrameComponents,
    allowedOrdersComponents,
    allowedOffersComponent,
    allowedCMSComponent,
    allowedTransactionComponents,
    allowedCancelRequestComponents,
    allowedClusterComponents
  };
};

export default useRoutes;
