import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./typeForm.module.scss";
import useTypeForm from "./useTypeForm";
import {
  capitalizeInitial,
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";

const TypeForm = ({ refetch, closeModal, isStickyFooter }) => {
  const {
    formik,
    imageCoverPreview,
    imageThumbnailPreview,
    selectedItemsDetails,
    profilefileInputRef,
    handleThumbnailImage,
    handleCoverImage,
    is_edit
  } = useTypeForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Type name*"}
        type="text"
        id="name
          "
        name="name
          "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />

      <Input
        label={"Rate*"}
        type="text"
        id="rate
          "
        name="rate
          "
        className={`pro-input lg ${
          formik.errors.rate && formik.touched.rate && "error"
        }`}
        {...formik.getFieldProps("rate")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.rate && formik.touched.rate}
        errorMessage={formik.errors.rate}
        disabled={is_edit ? true : false}
      />

      <Input
        label={"SKU*"}
        type="text"
        id="sku
          "
        name="sku
          "
        className={`pro-input lg ${
          formik.errors.sku && formik.touched.sku && "error"
        }`}
        {...formik.getFieldProps("sku")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.sku && formik.touched.sku}
        errorMessage={formik.errors.sku}
      />

      <Input
        label={"Boarder Width*"}
        type="text"
        id="border_width
          "
        name="border_width
          "
        className={`pro-input lg ${
          formik.errors.border_width && formik.touched.border_width && "error"
        }`}
        {...formik.getFieldProps("border_width")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.border_width && formik.touched.border_width}
        errorMessage={formik.errors.border_width}
      />
      <Input
        label={"Inner Padding*"}
        type="text"
        id="inner_padding
          "
        name="inner_padding
          "
        className={`pro-input lg ${
          formik.errors.inner_padding && formik.touched.inner_padding && "error"
        }`}
        {...formik.getFieldProps("inner_padding")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.inner_padding && formik.touched.inner_padding}
        errorMessage={formik.errors.inner_padding}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Thumbnail*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.thumbnail && formik.errors.thumbnail && " error"
            }`}
            id="thumbnail"
            name="thumbnail"
            onBlur={formik.handleBlur("thumbnail")}
            onChange={(e) => handleThumbnailImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                  formik?.values?.thumbnail?.name !== undefined
									? limitStrLength(formik?.values?.thumbnail?.name, 30)
									: selectedItemsDetails !== "" &&
										selectedItemsDetails?.thumbnail
										? selectedItemsDetails?.thumbnail
										: `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.thumbnail && formik.errors.thumbnail && (
            <span className="error-text">{formik.errors.thumbnail}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageThumbnailPreview}
            alt={`branch - 01`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
				<label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
					Image*
				</label>
				<div className="input-drag">
					<input
						type="file"
						placeholder="placeholder"
						accept=".jpeg, .jpg , .png"
						ref={profilefileInputRef}
						className={`pro-input ${formik.touched.image && formik.errors.image && " error"
							}`}
						id="image"
						name="image"
						onBlur={formik.handleBlur("image")}
						onChange={(e) => handleCoverImage(e)}
					/>
					<span className="input-drag-box">
						<IconText
							title={
								formik?.values?.image?.name !== undefined
									? limitStrLength(formik?.values?.image?.name, 30)
									: selectedItemsDetails !== "" &&
										selectedItemsDetails?.image
										? selectedItemsDetails?.image
										: `Drop files to attach or browse`
							}
						/>
					</span>
					{formik.touched.image && formik.errors.image && (
						<span className="error-text">{formik.errors.image}</span>
					)}
				</div>
			</div>
			<div className={`col-2 pro-my-2`}>
				<div className={`${Style.root_image_inner}`}>
					<Image
						width={100}
						height={100}
						src={imageCoverPreview}
						alt={`branch - 01`}
					/>
				</div>
			</div>

      <Input
        label={"Video Url"}
        type="text"
        id="video_url
          "
        name="video_url
          "
        className={`pro-input lg ${
          formik.errors.video_url && formik.touched.video_url && "error"
        }`}
        {...formik.getFieldProps("video_url")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.video_url && formik.touched.video_url}
        errorMessage={formik.errors.video_url}
      />

      {/* toggle button start  */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => { formik.setFieldValue("status", !Boolean(formik.values?.status) ?? 0); }}
          />
          <span></span>
        </div>
        
      </div>
      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default TypeForm;
