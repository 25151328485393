import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetOrderDetailDataQuery } from "../../../store/queries/orders";
import {
  getAddressEditData,
  updateConfig,
} from "../../../store/slices/Orders/ordersSlice";

const useOrderShippingDetails = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const { orderDetailsEditModal } = useSelector((state) => state.orders);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetOrderDetailDataQuery({
    order_id: activeProfile,
  });

  const userDetailLabel = {
    coupon: "Coupon Code",
    discount: "Discount Amount",
    is_gift: "Is Gift",
    amount: "Gift Amount",
    charge: "Shipping Charge",
  };

  const userDetailValue = {
    
    coupon: `${
      profileData?.data?.result?.coupon_code ?? "Not Applicable"
    }`,
    discount: `${
      profileData?.data?.result?.discount_amount ?? 0
    }`,
    is_gift: `${
      profileData?.data?.result?.is_gift ? "Yes " : "No"
    }`,
    amount: `${
      profileData?.data?.result?.gift_amount ?? 0
    }`,
    charge: `${
      profileData?.data?.result?.shipment_amount ?? 0
    }`,
  };

  const orderDetails = Object.keys(userDetailValue).map((key) => {
    return {
      label: userDetailLabel[key],
      value:
        typeof userDetailValue[key] === "string"
          ? userDetailValue?.[key]
          : typeof userDetailValue[key] === "number"
          ? userDetailValue?.[key]
          : userDetailValue?.[key]?.name,
    };
  });

  const handleEditClick = () => {
        dispatch(
          updateConfig((state) => {
            state.orderDetailsEditModal = true;
            state.orderDetails = {
              order_id: profileData?.data?.result?.id,
              offer_id: profileData?.data?.result?.coupon_code_id ?? null,
              coupon_code: profileData?.data?.result?.coupon_code ?? null,
              discount_amount: profileData?.data?.result?.discount_amount ?? 0,
              is_gift: profileData?.data?.result?.is_gift,
              shipment_amount: profileData?.data?.result?.shipment_amount ?? 0
            };
          })
        );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.orderDetailsEditModal = false;
      })
    );
  };

  return {
    profileData: profileData?.data,
    orderDetails,
    isFetching,
    isSuccess,
    isError,
    activeProfile,
    refetch,
    closeModal,
    handleEditClick,
    orderDetailsEditModal,
  };
};

export default useOrderShippingDetails;
