import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetBulkEnquiryListDataQuery, useUpdateBulkEnquiryStatusMutation, useUpdateTableFeildsDataMutation } from "../../../store/queries/bulkEnquiries";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/bulkEnquiries/bulkEnquirySlice";
import { toast } from "react-toastify";
import Select from "react-select";

const useBulkEnquiry = () => {

     const menuState = useSelector(state => state.bulkEnquiry)
     const { tableFields, showEditModal } = useSelector((state) => state.global);
     const dispatch = useDispatch();
     const [skip, setSkip] = useState(true);

     const [updateTableFeildsData] = useUpdateTableFeildsDataMutation();
     const {
          data: mainData = {},
          isFetching,
          isLoading,
          refetch,
     } = useGetBulkEnquiryListDataQuery({
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState.currentPage,
          is_active: menuState.is_active
     });

     let paginationOptions = [
          { label: "10 Per page", value: 10 },
          { label: "20 Per page", value: 20 },
          { label: "30 Per page", value: 30 },
     ];

     const outputArray = [
          {
          label: "INITIATED",
          value: 0
     },
     {
          label: "PENDING",
          value: 1
     },
     {
          label: "COMPLETED",
          value: 2
     },

]

  const [updateStatus] = useUpdateBulkEnquiryStatusMutation();
     const handleSearch = (e) => {
          dispatch(
               updateConfig((state) => {
                    state.search = e.target.value;
               })
          );
     };

     const handleEditClick = () => {
          dispatch(
               globalUpdateConfig((state) => {
                    state.showEditModal = true;
               })
          );
     };

 

     const handleSort = (label) => {
          if (menuState.sortBy === label) {
               dispatch(
                    updateConfig((state) => {
                         state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
                    })
               );
          } else {
               dispatch(
                    updateConfig((state) => {
                         state.sortBy = label;
                         state.sortOrder = "asc";
                    })
               );
          }
     };
     const getRow = (feild, data) => {
          const rows = {
               common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
               status: (field, data) => {
                    let selectVal = outputArray.find((item) => item.value === data[field]);
                    return (
                      <Select
                        id="status"
                        name="status"
                        placeholder={"Select"}
                        className={`pro-input lg`}
                        classNamePrefix="pro-input"
                        options={outputArray}
                        getOptionLabel={(option) => option?.label}
                        getOptionValue={(option) => option?.value}
                        value={selectVal}
                        onChange={(selectedOption) => {
                          const formData = {
                              id: data.id,
                            status: selectedOption?.value,
                          };
                            updateStatus(formData)
                              .then((response) => {
                                if (response?.data?.success) {
                                  toast.success("Status Updated Successfully!");
                                  refetch?.();
                                } else if (!response?.data?.success) {
                                  toast.error("Failed to Update Status!");
                                } else {
                                  toast.error("Failed to Update Status!");
                                }
                              })
                              .catch(() => {
                                toast.error("Failed to Update Status!");
                              });
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                    //     isDisabled={data[field] === "SETTLED" ? true : false}
                      />
                    );
                  },
          };

          return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
     };

     const handlePagination = (page) => {
          dispatch(
               updateConfig((state) => {
                    state.currentPage = page;
               })
          );
     };

     const handlePageSize = ({ value: page_size }) => {
          dispatch(
               updateConfig((state) => {
                    state.currentPageSize = page_size;
                    state.currentPage = 1;
               })
          );
     };
     const closeEditModal = () => {
          setSkip(() => true);
          dispatch(
               globalUpdateConfig((state) => {
                    state.showEditModal = false;
               })
          );
          dispatch(
               updateConfig((state) => {
                    state.selectedItemsDetails = "";
               })
          );
     };

     let actionOptions = [
          { label: "Edit Columns", value: 0 },
        ];
      
        const handleActionChange = (item) => {
          if (item?.value === 0) {
            dispatch(
              globalUpdateConfig((state) => {
                state.showEditModal = true;
              })
            );
          }
        };

  return {mainData,
    menuState,
    isLoading,
    isFetching,
    handleSearch,
    handleEditClick,
    handleSort,
    getRow,
    currentPage:menuState.currentPage,
    paginationOptions,
    handlePagination,
    handlePageSize,
    showEditModal,
    closeEditModal,
    refetch,
    updateTableFeildsData,
    handleActionChange,
    actionOptions
  }
}

export default useBulkEnquiry
