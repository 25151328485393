import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { getFormatedDate } from "../../utils/functions/table";
import { addDays } from "date-fns";
import {
  updateConfig,
  // updateStatus
} from "../../store/slices/CancelRequest/cancelRequestSlice.js";
import {
  cancelRequest,
  useGetListDataQuery,
  useUpdateStatusMutation,
  useUpdateTableFieldsDataMutation,
} from "../../store/queries/cancelRequest";
import styles from "./cancelRequest.module.scss";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";

const useCancelRequest = (dashboard) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.cancelRequest);

  const { showCreateModal, selctedId } = useSelector(
    (state) => state.cancelRequest
  );
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortById?.value,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState.currentPage,
    from: menuState?.startDate ? menuState.startDate : "",
    to: menuState?.endDate ? menuState.endDate : "",
  });

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateBrancheFields] = useUpdateTableFieldsDataMutation();
  const [updateStatus] = useUpdateStatusMutation();

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      action: (feild, data) => {
        return (
          <p className={`pro-mb-0`}>
            {data.action === "REQUESTED" ? (
              <div className="pro-mb-0 pro-d-flex pro-gap-2">
                <button
                  className={`${styles.text_green}  pro-btn pro-p-1 pointer pro-d-inline-flex`}
                  onClick={() => {
                    handleAction(data?.id, 2);
                  }}
                >
                  <span class="material-symbols-outlined x4">check_circle</span>
                </button>
                {/* error  */}
                <button
                  className={`${styles.text_red}  pro-btn pro-p-1 pointer pro-d-inline-flex`}
                  onClick={() => {
                    handleAction(data?.id, 3);
                  }}
                >
                  <span class="material-symbols-outlined x4">cancel</span>
                </button>
              </div>
            ) : (
              <>{data[feild]}</>
            )}
          </p>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleAction = (order_id, status) => {
    const obj = {
      order_id: order_id,
      status: status,
    };
    updateStatus(obj);
    dispatch(
      cancelRequest.util.updateQueryData(
        "getListData",
        {
          sort_by: menuState.sortById?.value,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState.currentPage,
          from: menuState?.startDate ? menuState.startDate : "",
          to: menuState?.endDate ? menuState.endDate : "",
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.result?.map((res) =>
            res.id === order_id
              ? {
                  ...res,
                  action: status === 2 ? "APPROVED" : "REJECTED",
                }
              : res
          );
          currentCache = {
            ...currentCache,

            data: {
              ...currentCache.data,
              result: newResult,
            },
          };
          return currentCache;
        }
      )
    );

    // dispatch(updateStatus(obj)).then((response) => {
    //   if (response?.payload?.data?.status_code === 200) {
    //     refetch();
    //     toast.success(response?.payload?.data?.message);
    //   } else if (response?.payload?.status_code === 400) {
    //     toast.error(response?.payload?.message);
    //   } else toast.error(response?.payload?.message);
    // });
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.is_active = selectedOption.value;
      })
    );
  };

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    handleActionChange,
    refetch,
    updateBrancheFields,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleChange,
    tableRef,
    setShowDeleteConfirm,
    setIsFilterShow,
    isFilterShow,
  };
};

export default useCancelRequest;
