import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useCMS from "./useCMS";

const CMS = () => {
  const { drawerMenu } = useCMS();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default CMS;
