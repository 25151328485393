import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateConfig,
} from "../../../store/slices/ShippingPolicy/shippingPolicySlice.js";
import { useGetListDataQuery} from "../../../store/queries/shippingPolicy/index.js";

const useShippingPolicy = (dashboard) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.shippingPolicy);
  const [skip, setSkip] = useState(true);

  const { showEditModal } = useSelector((state) => state.shippingPolicy);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    type: 3 
  });

  // const [updateStatus] = useUpdateStatusByIdMutation();

  if(mainData) {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = mainData?.data?.result;
      })
    );
  }

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.is_edit = true;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.is_edit = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };


  return {
    mainData,
    menuState,
    isFetching,
    isLoading,
    showEditModal,
    handleEditAction,
    refetch,
    closeModal,
    handleEditClick,
    closeEditModal,
  };
};

export default useShippingPolicy;
