import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Orders/ordersSlice";
import { toast } from "react-toastify";
import { useMemo, useState } from "react";
import { createItemOrder, createOrder, getAddressData, getAutoCompleteData } from "../api";
import { useGetStateDataQuery } from "../../../store/queries/orders";
import { emailRegExp, phoneRegExp } from "../../../utils/functions/table";
import clusterData from "../../../utils/components/clusterData";

const useAddOrder = (refetch, closeModal, type, orderId) => {
  const dispatch = useDispatch();

  const { is_edit, autoCompleteData, addresses } = useSelector(
    (state) => state.orders
  );

  const { data: OptionData = {} } = useGetStateDataQuery();

  const [userType, setUserType] = useState("existing"); // 'existing' or 'new'
  const [formSubmit, setFormSubmit] = useState(false);

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    if (event.target.value === "new") {
      setOpenAddress(true);
    } else {
      setOpenAddress(false);
    }
  };

  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const [openAddress, setOpenAddress] = useState(false);

  const handleAddressChange = (event) => {
    setSelectedAddressId(parseInt(event.target.value, 10));

    formik.setFieldValue("user_address_id", parseInt(event.target.value, 10));
    setOpenAddress(false);
  };

  const [initialData, setInitialData] = useState({
    user_id: "",
    user_address_id: "",
    user_name: "",
    full_name: "",
    mobile_no: "",
    email: "",
    pin_code: "",
    address_1: "",
    address_2: "",
    state_id: "",
    city: "",
    mobile_no_2: "",
    is_shipping: false,
    is_gift: false,
    discount_amount: 0,
    item: [
      {
        product_type_id: "",
        cluster_type_id: "",
        frame_type_id: "",
        color_type_id: "",
        finish_type_id: "",
        hang_type_id: "",
        size_type_id: "",
        custom_size: "",
        custom_size_sku: "",
        custom_size_rate_without_tax: 0,
        custom_size_rate: 0,
      },
    ],
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: type === "new" ? Yup.object({
      user_id:
        userType === "existing"
          ? Yup.string().required("Select user")
          : Yup.string(),
      user_name:
        userType === "new" ? Yup.string().required("Required") : Yup.string(),
      full_name: openAddress
        ? Yup.string().required("*Required")
        : Yup.string(),
      mobile_no: openAddress
        ? Yup.string()
            .matches(phoneRegExp, "Please enter a valid phone number")
            .min(10, "Phone number must be 10 digits")
            .max(10, "Phone number must be 10 digits")
            .required("*Required")
        : Yup.string(),
      email: openAddress
        ? Yup.string()
            .matches(emailRegExp, "Please enter a valid email")
            .required("*Required")
        : Yup.string(),
      pin_code: openAddress
        ? Yup.number()
            .typeError("Must be a number")
            .integer("PIN code must be an integer")
            .positive("Must be a positive number")
            .min(100000, "PIN code must have at least 6 digits")
            .max(999999, "PIN code can have at most 6 digits")
            .required("*Required")
        : Yup.number(),
      address_1: openAddress
        ? Yup.string().required("*Required")
        : Yup.string(),
      state_id: openAddress ? Yup.string().required("*Required") : Yup.string(),
      city: openAddress ? Yup.string().required("*Required") : Yup.string(),
      item: Yup.array().of(
        Yup.object().shape({
          product_type_id: Yup.string().required("*Required"),
          frame_type_id: Yup.string().required("*Required"),
          color_type_id: Yup.string().required("*Required"),
          finish_type_id: Yup.string().required("*Required"),
        })
      ),
      discount_amount: Yup.number()
        .typeError("Discount Amount must be a number")
        .min(0, "Minimum Discount amount is 0")
        .required("*Required"),
    }) : Yup.object({
      item: Yup.array().of(
        Yup.object().shape({
          product_type_id: Yup.string().required("*Required"),
          frame_type_id: Yup.string().required("*Required"),
          color_type_id: Yup.string().required("*Required"),
          finish_type_id: Yup.string().required("*Required"),
        })
      ),
    }),
    validate: (values) => {
      const errors = {};

      values?.item?.forEach((value, index) => {
        if (value?.product_type_id === 2 && value?.cluster_type_id === "") {
          errors[`item[${index}].cluster_type_id`] = "*Required";
        }
        if (
          (value?.product_type_id === 1 || value?.product_type_id === "") &&
          value?.size_type_id === ""
        ) {
          errors[`item[${index}].size_type_id`] = "*Required";
        }
        if (value?.size_type_id === "0") {
          if (!value?.custom_size?.trim()) {
            errors[`item[${index}].custom_size`] = "*Required";
          }
          if (!value?.custom_size_sku?.trim()) {
            errors[`item[${index}].custom_size_sku`] = "*Required";
          }
          if (value?.custom_size_rate_without_tax === "") {
            errors[`item[${index}].custom_size_rate_without_tax`] = "*Required";
          } else if (value?.custom_size_rate_without_tax) {
            // Number (integer or decimal) regex validation
            const numberRegex = /^\d+(\.\d+)?$/;
            if (!numberRegex.test(value.custom_size_rate_without_tax)) {
              errors[`item[${index}].custom_size_rate_without_tax`] =
                "Please enter a valid number.";
            }
            if (+value?.custom_size_rate_without_tax >= +value?.custom_size_rate) {
              errors[`item[${index}].custom_size_rate_without_tax`] = "Custom size rate without tax should be less than Custom size rate";
            }
            
          }
          if (!value?.custom_size_rate) {
            errors[`item[${index}].custom_size_rate`] = "*Required";
          } else if (value?.custom_size_rate) {
            // Number (integer or decimal) regex validation
            const numberRegex = /^\d+(\.\d+)?$/;
            if (!numberRegex.test(value.custom_size_rate)) {
              errors[`item[${index}].custom_size_rate`] =
                "Please enter a valid number.";
            }
          }
        }
        if (
          (value?.product_type_id === 1 || value?.product_type_id === "") &&
          value?.hang_type_id === ""
        ) {
          errors[`item[${index}].hang_type_id`] = "*Required";
        }
      });

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      setFormSubmit(true);
      let obj = {};
      if(type === "new") {
obj.is_shipping= +values.is_shipping;
obj.is_gift= +values.is_gift;
        obj.discount_amount= +values?.discount_amount;
      }
      else {
        obj.order_id = orderId;
      }
      // let obj = {
      //   is_shipping: +values.is_shipping,
      //   is_gift: +values.is_gift,
      //   discount_amount: values?.discount_amount,
      // };
      if (userType === "new") {
        obj.user_name = values?.user_name;
      } else {
        obj.user_id = values?.user_id;
        if (values?.user_address_id !== null) {
          obj.user_address_id = values?.user_address_id;
        }
      }

      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (openAddress) {
        let address = {
          full_name: values?.full_name ?? "",
          phone_number: values?.mobile_no ?? "",
          email: values?.email ?? "",
          pincode: values?.pin_code ?? "",
          address_line1: values?.address_1 ?? "",
          state_id: values?.state_id ?? "",
          city: values?.city ?? "",
        };

        if (values?.address_2) {
          address.address_line2 = values.address_2;
        }

        if (values?.mobile_no_2) {
          address.alternate_phone_number = values.mobile_no_2;
        }
        // Append each property individually
        Object.entries(address).forEach(([key, value]) => {
          formData.append(`address[${key}]`, value);
        });
      }
      values?.item?.forEach((value, index) => {
        formData.append(
          `items[${index}][product_type]`,
          value?.product_type_id
        );
        formData.append(`items[${index}][frame_type_id]`, value?.frame_type_id);
        formData.append(`items[${index}][color_type_id]`, value?.color_type_id);
        formData.append(
          `items[${index}][finish_type_id]`,
          value?.finish_type_id
        );

        if (value?.product_type_id === 2) {
          formData.append(
            `items[${index}][cluster_id]`,
            value?.cluster_type_id
          );
          // let dimensions = clusterData.
          const clusterOfType1 = clusterData.find(
            (cluster) => cluster.type === value?.cluster_type_id.toString()
          );
          // Check if the cluster is found
          if (clusterOfType1) {
            // Process the data array to extract width and height
            const dimensions = clusterOfType1.data.map((item) => {
              const [width, height] = item.dimension.split("*").map(Number);

              return {
                width,
                height,
              };
            });
            dimensions.forEach((dimension, idx) => {
              formData.append(
                `items[${index}][dimensions][${idx}][width]`,
                dimension.width
              );
              formData.append(
                `items[${index}][dimensions][${idx}][height]`,
                dimension.height
              );
            });
          }
        }
        if (value?.size_type_id === "0") {
          formData.append(`items[${index}][custom_size]`, value?.custom_size);
          formData.append(
            `items[${index}][custom_size_sku]`,
            value?.custom_size_sku
          );
          formData.append(
            `items[${index}][custom_size_rate_without_tax]`,
            value?.custom_size_rate_without_tax
          );
          formData.append(
            `items[${index}][custom_size_rate]`,
            value?.custom_size_rate
          );
        }
        if (value?.product_type_id !== 2) {
          formData.append(
            `items[${index}][size_type_id]`,
            +value?.size_type_id
          );
          formData.append(`items[${index}][hang_type_id]`, value?.hang_type_id);
        }
      });

      if(type === "new") {
        createOrder(formData).then((response) => {
          setFormSubmit(false);
          if (response?.data?.status_code === 200) {
            resetForm();
            closeModal();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.autoCompleteData = [];
                state.addresses = [];
              })
            );
            toast.success("New Order Created");
          } else if (response?.data?.status_code === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
      else {
        createItemOrder(formData).then((response) => {
          setFormSubmit(false);
          if (response?.data?.status_code === 200) {
            resetForm();
            closeModal();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.autoCompleteData = [];
                state.addresses = [];
              })
            );
            toast.success("New Order Created");
          } else if (response?.data?.status_code === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
      
    },
  });

  const handleAddField = () => {
    let { item } = formik?.values;
    item = [
      ...item,
      {
        product_type_id: "",
        cluster_type_id: "",
        frame_type_id: "",
        color_type_id: "",
        finish_type_id: "",
        hang_type_id: "",
        size_type_id: "",
        custom_size_rate: "",
        custom_size_rate_without_tax: "",
        custom_size_sku: "",
        custom_size: "",
      },
    ];
    formik.setFieldValue("item", item);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const useDataOptions = useMemo(
    () =>
      autoCompleteData?.map((opt) => {
        return { value: opt?.id, label: opt?.email, id: opt?.id };
      }),
    // eslint-disable-next-line
    [autoCompleteData]
  );
  let userVal = useDataOptions.find(
    (item) => item.value === formik?.values?.user_id
  );

  const handleInputChange = (item) => {
    if (item?.length >= 1) {
      getAutoCompleteData(item).then((response) => {
        dispatch(
          updateConfig((state) => {
            state.autoCompleteData = response?.data?.data;
          })
        );
      });
    }
  };

  const handleParentId = (val) => {
    formik.setFieldValue("user_id", val?.value);
    setOpenAddress(false);
    const obj = {
      id: val?.value,
    };

    getAddressData(obj).then((response) => {
      dispatch(
        updateConfig((state) => {
          state.addresses = response?.data?.data;
        })
      );
      if (response?.data?.data.length !== 0) {
        setSelectedAddressId(response?.data?.data[0].id);
        setOpenAddress(false);
        formik.setFieldValue("user_address_id", response?.data?.data[0].id);
      } else {
        setOpenAddress(true);
        setSelectedAddressId(null);
        formik.setFieldValue("user_address_id", null);
      }
    });
  };

  const addAddress = () => {
    setOpenAddress(true);
    setSelectedAddressId(null);
    formik.setFieldValue("user_address_id", null);
  };

  const closeAddress = () => {
    setOpenAddress(false);
  };

  const handleDiscount = (value) => {
    
    if(+value == 0){
      formik.setFieldValue("discount_amount", 0);
    }
    else {
      formik.setFieldValue("discount_amount", +value);
    }

  }

  return {
    getFieldError,
    formik,
    is_edit,
    handleAddField,
    handleUserTypeChange,
    userType,
    useDataOptions,
    userVal,
    handleInputChange,
    handleParentId,
    addresses,
    selectedAddressId,
    handleAddressChange,
    addAddress,
    openAddress,
    closeAddress,
    OptionData,
    formSubmit,
    handleDiscount
  };
};

export default useAddOrder;
