import { useFormik } from "formik"
import * as Yup from 'yup'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'
import {
  updateConfig,
  createSeasonBasedOffer,
  updateSeasonBasedOffer,
} from "../../../../store/slices/SeasonBasedOffers/seasonBasedOffersSlice";


const useSeasonBasedOfferForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);
  const { is_edit, selectedId, selectedItemsDetails } = useSelector((state) => state.seasonBasedOffers);
  const [is_offer_Type, setOfferType] = useState(selectedItemsDetails !== "" ? selectedItemsDetails?.offer_type.toString() : "");

  const Options = [
    {
      name: "Amount",
      id: "0"
    },
    {
      name: "Percentage",
      id: "1",
    }, 
  ]

  const validation = Yup.object({
    code: Yup.string()
      .min(2, "The code must be at least 2 characters")
      .required("*Required"),
    title: Yup.string()
      .min(2, "The title must be at least 2 characters")
      .required("*Required"),
    description: Yup.string()
      .min(2, "The description must be at least 2 characters")
      .required("*Required"),
    validity_to: Yup.string().required("*Required"),
    validity_from: Yup.string()
    .required("*Required"),
    offer_type: Yup.string().required("*Required"),
    percentage: is_offer_Type === "1" ? Yup.number().typeError('Percentage must be a number').required("*Required") : "",
    amount: is_offer_Type === "0" ? Yup.number().typeError('Amount must be a number').required("*Required") : "",
  });

  const validateForm = (values) => {
    const errors = {};
  
    if (values.validity_from && values.validity_to) {
      const validityFromDate = new Date(values.validity_from);
      const validityToDate = new Date(values.validity_to);
  
      if (validityFromDate > validityToDate) {
        errors.validity_to = "Validity to date must be greater than or equal to Validity from date";
      }
    }
  
    // Add other validation rules if needed
  
    return errors;
  };
  
  

  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== ""
        ? selectedItemsDetails.id
        : "",
      code: selectedItemsDetails !== ""
        ? selectedItemsDetails.code
        : "",
      title: selectedItemsDetails !== ""
        ? selectedItemsDetails.title
        : "",
      description: selectedItemsDetails !== ""
        ? selectedItemsDetails.description
        : "",
      validity_to: selectedItemsDetails !== ""
        ? selectedItemsDetails.validity_to
        : "",
      validity_from: selectedItemsDetails !== ""
        ? selectedItemsDetails.validity_from
        : "",
      offer_type: selectedItemsDetails !== ""
        ? selectedItemsDetails?.offer_type.toString()
        : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 0
          : 0,
      percentage: selectedItemsDetails !== ""
      ? selectedItemsDetails?.offer_type.toString() == "1"
        ? selectedItemsDetails?.percentage
        : ""
      : "",
      amount: selectedItemsDetails !== ""
      ? selectedItemsDetails?.offer_type.toString() == "0"
        ? selectedItemsDetails?.amount
        : ""
      : "",
      // amount: selectedItemsDetails !== ""
      //   ? selectedItemsDetails.amount
      //   : "",
    },
    validationSchema: validation,
    validate: validateForm, 
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {

      let obj = {
        id: is_edit ? selectedId : "",
        code: values.code,
        title: values.title ? values.title : "",
        description: values.description ? values.description : "",
        validity_to: values.validity_to ? values.validity_to : "",
        validity_from: values.validity_from ? values.validity_from : "",
        offer_type: values.offer_type,
        status: +values.status,
        applicable_to: 3,
        is_seasonal: 1,
      };

      if(values.offer_type == "1") {
        obj.percentage = values?.percentage;
      }
      else{
        obj.amount = values?.amount;
      }



      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        dispatch(
          updateSeasonBasedOffer({ data: formData })
        ).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createSeasonBasedOffer(formData)).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },

  })




  return {
    selectedItemsDetails,
    profilefileInputRef,
    formik,
    Options,
    setOfferType,
  }
}

export default useSeasonBasedOfferForm
